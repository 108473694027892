import { Button, Divider, Form, Input, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Fragment } from 'react';
import { RegistrationFields, useAuth } from 'src/context/AuthProvider';
import { FormType } from '../..';

type RegistrationFormProps = {
  setFormType: React.Dispatch<React.SetStateAction<FormType>>;
};

export const RegistrationForm: React.FC<RegistrationFormProps> = ({ setFormType }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = useForm();

  const { register } = useAuth();

  const onFinish = async ({ email, password, username }: Record<RegistrationFields, string>) => {
    try {
      await register({ email, password, username });
      setFormType('success-registration');
    } catch (error) {
      if (typeof error === 'string') {
        messageApi.error(error);
        return;
      }

      if ((error as { message: string }).message) {
        messageApi.error((error as { message: string }).message);
      }
    }
  };

  return (
    <Fragment>
      {contextHolder}
      <Form
        form={form}
        name={'registration-form'}
        layout={'vertical'}
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          name={'username'}
          label="Name"
          rules={[
            {
              required: true,
              message: 'Please input your name!',
            },
          ]}
        >
          <Input size="large" placeholder="Enter your name" />
        </Form.Item>
        <Form.Item
          name={'email'}
          label="Email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input size="large" type="email" placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          name={'password'}
          label="Password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input size="large" type="password" placeholder="Enter your password" />
        </Form.Item>
        <Form.Item>
          <Button type={'primary'} htmlType={'submit'} size={'large'}>
            CREATE ACCOUNT
          </Button>
        </Form.Item>
      </Form>
      <Divider style={{ borderBlockStartColor: '#fff' }}>OR</Divider>
      <div className="auth-message-container">
        <div className="auth-message">
          <span>Already have an account?</span>
          <Button type={'link'} onClick={() => setFormType('login')}>
            SIGN IN
          </Button>
        </div>
      </div>
    </Fragment>
  );
};
