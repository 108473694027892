import { Button, Divider, Form, Input, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Fragment } from 'react';
import { FormType } from '../..';
import { LoginFields, useAuth } from 'src/context/AuthProvider';
import { useApp } from 'src/components/app';

type LoginFormProps = {
  setFormType: React.Dispatch<React.SetStateAction<FormType>>;
};

export const LoginForm: React.FC<LoginFormProps> = ({ setFormType }) => {
  const { auth } = useApp();

  const [messageApi, contextHolder] = message.useMessage();

  const [form] = useForm();

  const { login } = useAuth();
  const onFinish = async ({ email, password }: Record<LoginFields, string>) => {
    try {
      await login({ email, password });
      messageApi.success("You've been logged!");
      auth.modal.setOpen(false);
    } catch (error) {
      if (typeof error === 'string') {
        messageApi.error(error);
        return;
      }

      if ((error as { message: string }).message) {
        messageApi.error('Incorrect Email or Password! Please try again.');
      }
    }
  };

  return (
    <Fragment>
      {contextHolder}
      <Form
        form={form}
        name={'login-form'}
        layout={'vertical'}
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          name={'email'}
          label="Email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input type="email" placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          name={'password'}
          label="Password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input type="password" placeholder="Enter your password" />
        </Form.Item>
        <Form.Item className="sumbit-form-item">
          <Button type={'primary'} htmlType={'submit'} size={'large'} block>
            LOGIN
          </Button>
        </Form.Item>
      </Form>
      <Divider style={{ borderBlockStartColor: '#fff' }}>OR</Divider>
      <div className="auth-message-container">
        <div className="auth-message">
          <span>New customer?</span>
          <Button type="link" onClick={() => setFormType('registration')}>
            SIGN UP
          </Button>
        </div>
        <Button type="link" onClick={() => setFormType('password-recover')}>
          FORGOT PASSWORD?
        </Button>
      </div>
    </Fragment>
  );
};
