import { FC } from 'react';
import { Divider, Row, Typography } from 'antd';
import CustomButton from '../../ui/CustomButton/CustomButton';
import { OrderNameWithImg } from '../order-name-with-image/OrderNameWithImg';
import './ConeDetails.less';
import { iCollapseDetailsItem } from '../../../pages/configurator/components/ResultStep';
import { Types } from 'src/enums/page';

export const ConeDetails: FC<
  { data: iCollapseDetailsItem } & {
    setCurrentStep?: (value: number) => void;
    isMobile?: boolean;
    editable?: boolean;
    subTypeId?: string | null | undefined;
  }
> = (props) => {
  const {
    setCurrentStep,
    isMobile,
    editable,
    data: { paperOption, coneType, size, filterCustomization, color, subTypeImg },
    subTypeId,
  } = props;

  return (
    <Row className={'collapse-body'} style={{ gap: isMobile ? 0 : 24 }}>
      <Row className={'collapse-row'} style={{ gap: isMobile ? 0 : 32 }} wrap={isMobile}>
        <Row className={'collapse-element'} style={{ maxWidth: isMobile ? 'none' : 288 }}>
          <Row className={'title'} justify={'space-between'}>
            <Typography.Text style={{ color: '#fff' }}>Paper Options</Typography.Text>
            {editable && setCurrentStep && (
              <CustomButton
                label={'Edit'}
                type={'link'}
                className={'edit-btn'}
                onClick={() =>
                  setCurrentStep(
                    subTypeId ? 4 : coneType.name.toLowerCase() === Types.paper ? 2 : 3
                  )
                }
              />
            )}
          </Row>
          <OrderNameWithImg
            name={paperOption?.attributes?.name!}
            icon={{
              src: paperOption?.attributes?.image?.data?.attributes?.url,
              type: 'circle',
            }}
          />
        </Row>
        <Divider type={isMobile ? 'horizontal' : 'vertical'} style={{ background: '#B084A3' }} />
        <Row className={'collapse-element'} style={{ maxWidth: isMobile ? 'none' : 288 }}>
          <Row className={'title'} justify={'space-between'}>
            <Typography.Text style={{ color: '#fff' }}>Type of Cones</Typography.Text>
            {/* {editable && setCurrentStep && (
              <CustomButton
                label={'Edit'}
                type={'link'}
                className={'edit-btn'}
                onClick={() => setCurrentStep(0)}
              />
            )} */}
          </Row>
          <OrderNameWithImg
            name={coneType?.name!}
            icon={{ src: subTypeImg ? subTypeImg : coneType?.image, type: 'circle' }}
          />
        </Row>
      </Row>
      <Divider type={'horizontal'} style={{ background: '#B084A3' }} />
      <Row className={'collapse-row'} style={{ gap: isMobile ? 0 : 32 }} wrap={isMobile}>
        <Row className={'collapse-element'} style={{ maxWidth: isMobile ? 'none' : 288 }}>
          <Row className={'title'} justify={'space-between'}>
            <Typography.Text style={{ color: '#fff' }}>Filter Colour</Typography.Text>
            {editable && setCurrentStep && (
              <CustomButton
                label={'Edit'}
                type={'link'}
                className={'edit-btn'}
                onClick={() => setCurrentStep(subTypeId ? 5 : 4)}
              />
            )}
          </Row>
          <Row className={'data'}>
            <div className={'collapse-filter-color'} style={{ background: color }} />
          </Row>
        </Row>
        <Divider type={isMobile ? 'horizontal' : 'vertical'} style={{ background: '#B084A3' }} />
        <Row
          className={'collapse-element'}
          style={{ maxHeight: isMobile ? 'none' : 100, maxWidth: isMobile ? 'none' : 288 }}
        >
          <Row className={'title'} justify={'space-between'}>
            <Typography.Text style={{ color: '#fff' }}>Filter Customization</Typography.Text>
            {editable && setCurrentStep && (
              <CustomButton
                label={'Edit'}
                type={'link'}
                className={'edit-btn'}
                onClick={() => setCurrentStep(subTypeId ? 6 : 5)}
              />
            )}
          </Row>
          <Row className={'data'} wrap={true} align={'middle'}>
            <Typography.Text style={{ color: '#fff' }}>Front:</Typography.Text>
            <img
              width={46}
              height={46}
              className={'filter-customization-img'}
              src={filterCustomization?.front?.url}
              alt="front"
            />
            <Divider type={'vertical'} style={{ background: '#B084A3' }} />
            <Typography.Text style={{ color: '#fff' }}>Back:</Typography.Text>
            {filterCustomization.back.url ? (
              <img
                width={46}
                height={46}
                className={'filter-customization-img'}
                src={filterCustomization.back.url}
                alt="front"
              />
            ) : (
              <Typography.Text style={{ color: filterCustomization.back.color }}>
                {filterCustomization.back.title}
              </Typography.Text>
            )}
          </Row>
        </Row>
      </Row>
      <Divider type={'horizontal'} style={{ background: '#B084A3' }} />
      <Row className={'collapse-element'} style={{ maxWidth: 'none' }}>
        <Row className={'title'} justify={'space-between'}>
          <Typography.Text style={{ color: '#fff' }}>Size</Typography.Text>
          {editable && setCurrentStep && (
            <CustomButton
              label={'Edit'}
              type={'link'}
              className={'edit-btn'}
              onClick={() =>
                setCurrentStep(subTypeId ? 3 : coneType.name.toLowerCase() === Types.paper ? 3 : 2)
              }
            />
          )}
        </Row>
        <Row className={'data'}>
          <OrderNameWithImg name={size?.value?.attributes?.name!} />
        </Row>
        <Row className={'size-info'} justify={'space-between'}>
          {size.description.map((item, idx) => {
            return (
              <Row className={'size-element'} key={idx}>
                <Typography.Text style={{ color: '#fff' }}>{item?.key!}</Typography.Text>
                <Typography.Text style={{ color: '#fff', fontWeight: 600 }}>
                  {item?.value}
                </Typography.Text>
              </Row>
            );
          })}
        </Row>
      </Row>
    </Row>
  );
};
