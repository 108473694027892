import { FC } from 'react';
import { Row, Space, Typography, Upload, Col, Progress } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { SvgIcon } from '../../icons/SvgIcon';
import { UploadFile } from 'antd/es/upload/interface';
import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import './index.less';
import { ReactComponent as InfoSVG } from '../../../assets/icons/info-icon.svg';
import { useApp } from 'src/components/app';
import { iCartItem } from 'src/types';
interface iProps {
  hint?: string;
  file: UploadFile | undefined;
  setFile: (value: UploadFile | undefined) => void;
  logoType?: string;
}

export const UploadContainer: FC<iProps> = ({ hint, file, setFile, logoType }) => {
  const {
    app: { cartItem, setCartItem },
  } = useApp();

  return (
    <Col span={24} className={'uploader'} style={{ fontWeight: 500 }}>
      {!file && !!hint && (
        <Row className={'info-box'}>
          <InfoSVG />
          <Typography.Text style={{ color: '#fff' }}>{hint}</Typography.Text>
        </Row>
      )}
      <Upload.Dragger
        className={`custom-upload ${!!file && 'active'}`}
        accept={'.png,.jpeg,.jpg'}
        showUploadList={{ downloadIcon: <CloseOutlined style={{ color: '#fff' }} /> }}
        listType={'picture'}
        // action={'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188'}
        customRequest={({ onSuccess }) => {
          if (typeof onSuccess === 'function') {
            onSuccess('ok');
          }
        }}
        name={'file'}
        onRemove={() => {
          setFile(undefined);
          if (logoType === 'backLogo' || logoType === 'frontLogo')
            cartItem &&
              setCartItem((prev: iCartItem | null) => ({
                ...prev,
                [logoType]: { ...prev?.[logoType], isUploaded: false },
              }));
        }}
        // headers={{ Authorization: `Bearer ${token}` }}
        onChange={(info: UploadChangeParam) => {
          setFile(info.fileList?.[0]);
          // if (info?.file?.response?.[0]?.id) {
          //   setFiles(prevState => ({ ...prevState, media: info?.file?.response?.map((it: { id: string }) => it.id) }))
          // }
        }}
        fileList={(file ? [file] : []) as UploadFile[]}
      >
        {file ? null : (
          <Space size={24} direction={'vertical'} style={{ maxWidth: 312 }}>
            <Row justify={'center'}>
              <SvgIcon type={'upload'} />
            </Row>
            <Space direction={'vertical'}>
              <Row justify={'center'}>
                <Typography.Text strong>Choose & Upload your file</Typography.Text>
              </Row>
              <Row justify={'center'}>
                <Typography.Text style={{ color: '#fff' }}>
                  Artwork should be submited in AI, PDF, PSD, EPS or CDR formats. 300 dpi minimum
                  for JPG, PNG or PSD artwork.
                </Typography.Text>
              </Row>
            </Space>
          </Space>
        )}
      </Upload.Dragger>
      {!!file?.percent && file?.percent !== 100 && (
        <Progress
          strokeWidth={16}
          percent={file?.percent}
          showInfo={false}
          strokeColor={'#EC008C'}
        />
      )}
      {file?.error?.message && (
        <Row gutter={[8, 0]} wrap={false} style={{ color: '#EC002A' }}>
          <Col>
            <WarningOutlined style={{ marginTop: 3 }} />
          </Col>
          <Col>
            <Typography.Text style={{ color: 'inherit' }}>{file?.error?.message}</Typography.Text>
          </Col>
        </Row>
      )}
    </Col>
  );
};
