import { iOrder } from '../types';
import { iCollapseDetailsItem } from '../pages/configurator/components/ResultStep';

export const formatOrderData = (data: iOrder) => {
  const { attributes } = data;
  const name = `${attributes?.type?.data?.attributes?.name} ${attributes?.paper_option?.data?.attributes?.name}`;
  const collapseData: iCollapseDetailsItem = {
    color: attributes?.color!,
    coneType: {
      name: attributes?.type?.data?.attributes?.name!,
      image: attributes?.type?.data?.attributes?.image?.data?.attributes?.url!,
    },
    paperOption: attributes?.paper_option?.data! as PaperOptionEntity,
    size: {
      value: attributes?.size as SizeEntity,
      description: [
        ...attributes?.size?.data?.attributes?.description!,
        {
          key: 'Filter',
          value: attributes?.filter?.data?.attributes?.value!,
          id: 'filter',
        },
      ] as Maybe<ComponentDataEntry>[],
    },
    filterCustomization: {
      front: {
        url: attributes?.frontLogo?.logo?.data?.attributes?.url!,
        angle: attributes?.frontLogo?.angle!,
        logoWidth: attributes?.frontLogo?.logoWidth || 85,
      },
      back: {
        url: attributes?.backLogo?.logo?.data?.attributes?.url!,
        angle: attributes?.backLogo?.angle!,
        title: attributes?.backLogo?.text!,
        color: attributes?.backLogo?.color!,
        logoWidth: attributes?.frontLogo?.logoWidth || 85,
      },
    },
    subTypeImg: attributes?.sub_type?.data?.attributes?.image?.data?.attributes?.url,
  };

  return { name, collapseData };
};
