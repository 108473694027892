import { MenuItem } from './MenuItem';
import './HeaderMenu.less';
import { FC, Fragment, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button, Typography } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import { useApp } from '../app';
import { Logo } from '../logo';
import { useOrdersQuery } from '../../graphql';
import { useAuth } from '../../context/AuthProvider';

type Props = {
  onMenuOpen: () => void;
  headerNavData: ComponentUiLink[];
  menuData: ComponentUiLink[];
  phone_number: string;
};

export const HeaderMenu: FC<Props> = ({ menuData, headerNavData, onMenuOpen, phone_number }) => {
  const navigate = useNavigate();
  const { auth } = useApp();
  const {
    app: { cartOrdersLength, setCartOrdersLength },
  } = useApp();
  const { user } = useAuth();

  const { data, loading } = useOrdersQuery({
    variables: {
      filters: {
        users_permissions_user: { id: { eq: user?.id! } },
        cart: { id: { eq: user?.cart?.data?.id } },
        shipment_order: { id: { eq: null } },
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setCartOrdersLength(data?.orders?.data?.length!);
  }, [data]);

  const isMaxTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  const hiddenLinks = ['/authorization', '/account'];

  const filteredLinks =
    menuData && menuData.filter((item) => !hiddenLinks.includes(item?.url as string));

  const {
    app: { setIsOpenCart, isOpenCart },
  } = useApp();
  return (
    <Fragment>
      <ul className={'header-menu'}>
        <li className={'header-nav-wrapper'}>
          {!isMaxTablet &&
            filteredLinks?.map((menuItem) => (
              <div key={menuItem?.id}>
                <NavLink to={menuItem.url as string}>
                  <Typography.Text className={'header-nav'}>{menuItem?.title}</Typography.Text>
                </NavLink>
              </div>
            ))}
        </li>
      </ul>

      {!isMaxTablet && <Logo />}
      <ul className={'header-menu'}>
        {!isMaxTablet && (
          <li>
            <Typography.Link className={'phone'} href={`tel:${phone_number}`}>
              {phone_number}
            </Typography.Link>
          </li>
        )}
        {headerNavData &&
          headerNavData.map((menuItem) => {
            return (
              <MenuItem
                key={menuItem?.id}
                title={menuItem.title === 'Cart' ? cartOrdersLength?.toString() : menuItem.title!}
                path={menuItem?.url}
                iconType={!isMaxTablet ? `${menuItem.iconType}Desktop` : menuItem.iconType}
                onClick={
                  {
                    '/account': () => navigate('/account'),
                    '/authorization': () => auth.modal.setOpen(true),
                    '/cart': () => setIsOpenCart(!isOpenCart),
                    '/my-cones': () => navigate('/my-cones'),
                  }[menuItem?.url]
                }
              />
            );
          })}

        {isMaxTablet && (
          <Button onClick={onMenuOpen} className={'open-button'}>
            <MenuItem iconType={'menu'} />
          </Button>
        )}
      </ul>
    </Fragment>
  );
};
