import { ReactNode } from 'react';
import { classNames } from '../../../helpers/classNames';
import './List.less';
export const List = <T,>({
  gap,
  items,
  renderItem,
  className,
  isRow,
}: {
  items: T[];
  renderItem: (i: T, idx: number) => ReactNode;
  className?: string;
  gap?: number;
  isRow?: boolean;
}) => {
  return (
    <ul className={classNames('custom-list', [className], { row: isRow })} style={{ gap }}>
      {items.map((i, idx) => (
        <li key={idx}>{renderItem(i, idx)}</li>
      ))}
    </ul>
  );
};
