import { iOrder } from 'src/types';

export const checkSampleCustomInArr = (products: iOrder[]) => {
  const sampleCustomProducts = products.filter(
    (el) => el.attributes?.isSample && !el.attributes?.isPreRolled
  );

  const customProducts = products.filter(
    (el) => !el.attributes?.isSample && !el.attributes?.isPreRolled
  );

  if (sampleCustomProducts.length === products.length) {
    return { areMoreSamplesThanCustomCones: false, isOnlySamplesInArr: true };
  }

  if (sampleCustomProducts.length > customProducts.length) {
    return { areMoreSamplesThanCustomCones: true, isOnlySamplesInArr: false };
  }
  return { areMoreSamplesThanCustomCones: false, isOnlySamplesInArr: false };
};

export const checkSampleCustomBeforeDeleting = (products: iOrder[], id: string) => {
  const customProductForDelete = products.find((el) => el.id === id);

  const customProducts = products.filter(
    (el) =>
      !el.attributes?.isSample &&
      !el.attributes?.isPreRolled &&
      el.attributes?.type?.data?.id === customProductForDelete?.attributes?.type?.data?.id
  );

  const sampleCustomProducts = products.filter(
    (el) =>
      el.attributes?.isSample &&
      !el.attributes?.isPreRolled &&
      el.attributes?.type?.data?.id === customProductForDelete?.attributes?.type?.data?.id
  );

  if (
    sampleCustomProducts.length < customProducts.length ||
    sampleCustomProducts.length === 0 ||
    customProductForDelete?.attributes?.isPreRolled
  ) {
    return {
      ableToDelete: true,
      type: customProductForDelete?.attributes?.type?.data?.attributes?.name,
    };
  } else {
    return {
      ableToDelete: false,
      type: customProductForDelete?.attributes?.type?.data?.attributes?.name,
    };
  }
};
