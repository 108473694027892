import { Button, Form, Input, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { useModalContext } from 'src/context/ModalProvider';
import { getAuthToken, setAuthToken } from 'src/helpers/auth';

export const ChangePasswordForm: React.FC = () => {
  const { closeModal, openModal } = useModalContext()
  const [form] = useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [messageApi, contextHolder] = message.useMessage()

  const onFinish = async (fields: any) => {
    setLoading(true)
    let response = null

    try {
      response = await fetch('/api/auth/change-password', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAuthToken()}`
        },
        body: JSON.stringify(fields)
      })

      if (500 === response.status) {
        messageApi.error("The provided current password is invalid")
        setLoading(false)
        return
      }
      response = await response.json()
    } catch (error) { }

    setLoading(false)
    
    if (response.error) {
      messageApi.error(response.error.message)
    } else {
      if (response.jwt) {
        setAuthToken(response.jwt)
      }
  
      openModal('request-successful', {
        message: 'Password successfully reset'
      })
    }
    
  }

  return (
    <div className="change-password-form">
      {contextHolder}
      <Form
        form={form}
        name={'change-password-form'}
        layout={'vertical'}
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          name={'currentPassword'}
          label="Current password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password size="large" placeholder="Enter current password" />
        </Form.Item>
        <Form.Item
          name={'password'}
          label="New password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value === getFieldValue('currentPassword')) {
                  return Promise.reject(new Error('Your new password must be different than your current password'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password size="large" placeholder="Enter your password" />
        </Form.Item>

        <Form.Item
          name={'passwordConfirmation'}
          label="Repeat new Password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match'));
              },
            }),
          ]}
        >
          <Input.Password size="large" placeholder="Enter your password" />
        </Form.Item>
      
        <div className="buttons-container">
          <Form.Item noStyle>
            <Button block className="secondary-btn" onClick={closeModal}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item noStyle>
            <Button block type="primary" htmlType="submit" disabled={loading}>
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
