import { Button } from 'antd';
import { FC, ReactNode } from 'react';
import './CustomButton.less';

type Props = {
  label: Maybe<string> | ReactNode;
  type?: 'primary' | 'link' | 'text' | 'default' | 'dashed' | undefined | 'dark';
  htmlType?: 'button' | 'submit' | 'reset' | undefined;
  className?: Maybe<string>;
  size?: 'large' | 'middle' | 'small';
  onClick?: (value?: any) => void;
  disabled?: boolean;
};

const CustomButton: FC<Props> = ({
  onClick,
  type = 'primary',
  label,
  htmlType,
  className,
  size,
  disabled = false,
}) => {
  const darkClassName = type === 'dark' ? 'dark-btn' : '';

  return (
    <Button
      size={size}
      type={type !== 'dark' ? type : 'default'}
      htmlType={htmlType}
      className={`custom-button ${darkClassName} ${className ? className : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
