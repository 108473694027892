import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import type { FormType } from 'src/new-components/Modals'

type ModalContextProps = {
  open: boolean
  formType: FormType | null
  openModal: (form: FormType, meta?: { [key: string]: any }) => void
  closeModal: () => void
  meta: {
    [key: string]: any
  }
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined)

export function useModalContext() {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used within an ModalProvider');
  }
  return context;
}

export const ModalProvider: FC<PropsWithChildren> = ({children}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType | null>(null);
  const [meta, setMeta] = useState({});

  const openModal = (form: FormType, params = {}) => {
    setOpen(true);
    setFormType(form);
    setMeta(params || {});
  }

  const closeModal = () => {
    setOpen(false)
    setFormType(null)
    setMeta({})
  }

  return (
    <ModalContext.Provider
      value={{
        open,
        formType,
        openModal,
        closeModal,
        meta
      }}
    >
      { children }
    </ModalContext.Provider>
  );
}
