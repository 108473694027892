import { FC } from 'react';
import { Image } from 'antd';

export const OrderImg: FC<{ src?: string; type?: 'circle' | 'square' }> = ({
  src,
  type = 'circle',
}) => {
  return (
    <Image
      className={'order-img'}
      height={43}
      width={43}
      preview={false}
      style={{
        borderRadius: type === 'circle' ? '50%' : 8,
        boxShadow: '0 0 0 1px white, 0 0 0 2.5px #33323A',
      }}
      src={src}
    />
  );
};
