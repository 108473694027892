import { Drawer } from 'antd';
import { FC, Fragment } from 'react';
import './MobileMenu.less';
import { Logo } from 'src/components/logo';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as Chevron } from '../../../../assets/icons/chevron-right.svg';
import { SvgIcon } from 'src/components/icons/SvgIcon';
import { useApp } from 'src/components/app';
import { useAuth } from 'src/context/AuthProvider';

type Props = {
  onMenuToggle: () => void;
  isOpen: boolean;
  menuItems: ComponentUiLink[];
};

export const MobileMenu: FC<Props> = ({ onMenuToggle, isOpen, menuItems }) => {
  const navigate = useNavigate();

  const { auth } = useApp();
  const { user } = useAuth();

  const filter = ['/about-us', '/contact', '/faq'];
  const mainLinks = menuItems && menuItems.filter((item) => !filter.includes(item?.url));
  const infoBlock = menuItems && menuItems.filter((item) => filter.includes(item?.url));
  return (
    <Drawer
      className={'drawer'}
      title={
        <Fragment>
          <NavLink to={'/'} onClick={onMenuToggle}>
            <Logo />
          </NavLink>
        </Fragment>
      }
      placement={'left'}
      onClose={onMenuToggle}
      open={isOpen}
    >
      <ul>
        {mainLinks &&
          mainLinks?.map((menuItem) => {
            const isAuthPath = menuItem.url === '/authorization';
            return (
              <li key={menuItem?.id} className={'mobile-menu-item'}>
                {isAuthPath ? (
                  <div
                    className={'mobile-menu-item-link'}
                    role={'button'}
                    onClick={() => (user ? navigate('/account') : auth.modal.setOpen(true))}
                  >
                    <div className={'mobile-menu-item-image'}>
                      <SvgIcon
                        type={menuItem.iconType?.toLowerCase()! as any}
                        className={'menu-icon'}
                      />
                    </div>
                    <span className={'mobile-menu-item-title'}>{menuItem?.title}</span>
                    <Chevron height={16} width={16} className={'mobile-menu-item-chevron'} />
                  </div>
                ) : (
                  <Link
                    to={menuItem?.url || ''}
                    className={'mobile-menu-item-link'}
                    onClick={onMenuToggle}
                  >
                    <div className={'mobile-menu-item-image'}>
                      <SvgIcon
                        type={menuItem.iconType?.toLowerCase()! as any}
                        className={'menu-icon'}
                      />
                    </div>
                    <span className={'mobile-menu-item-title'}>{menuItem?.title}</span>
                    <Chevron height={16} width={16} className={'mobile-menu-item-chevron'} />
                  </Link>
                )}
              </li>
            );
          })}
      </ul>

      <ul className={'mobile-menu-info'}>
        {infoBlock &&
          infoBlock.map((item, index) => (
            <li key={index} className={'mobile-menu-item'}>
              <Link
                className={'mobile-menu-item-link mobile-menu-item-title'}
                to={item.url}
                onClick={onMenuToggle}
              >
                {item.title}
                <Chevron height={16} width={16} className={'mobile-menu-item-chevron'} />
              </Link>
            </li>
          ))}
      </ul>
    </Drawer>
  );
};

