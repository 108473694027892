import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useState } from 'react';
import { useModalContext } from 'src/context/ModalProvider';
import { useApp } from 'src/components/app';

type PasswordRecoverProps = {
  onCancel: VoidFunction;
};

export const PasswordRecover: React.FC<PasswordRecoverProps> = ({ onCancel }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = useForm();
  const { auth } = useApp();
  const modal = useModalContext();

  async function onFinish(values: any) {
    setLoading(true);

    try {
      await fetch(`/api/auth/forgot-password-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      onCancel(); /// set login form in modal
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    auth.modal.setOpen(false);
    modal.openModal('password-recover-code', {
      email: values.email,
    });
  }

  return (
    <Form
      form={form}
      name={'password-recover-form'}
      layout={'vertical'}
      onFinish={onFinish}
      requiredMark={false}
    >
      <p style={{ color: '#fff' }}>
        To recover your password, enter the email address you used during registration. We will send
        you a code that you will need to enter.
      </p>

      <Form.Item
        name={'email'}
        label="Email"
        rules={[
          {
            required: true,
            message: 'Please input your your email or phone!',
          },
        ]}
      >
        <Input size="large" placeholder="Enter your email or phone" />
      </Form.Item>
      <div className="buttons-container">
        <Form.Item noStyle>
          <Button block className="secondary-btn" onClick={onCancel}>
            Cancel
          </Button>
        </Form.Item>
        <Form.Item noStyle>
          <Button block type="primary" htmlType="submit" disabled={loading}>
            Send
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};
