import { FC, Fragment } from 'react';
import { useFooterQuery } from '../../../graphql';
import Container from '../../ui/Container/Container';
import { Collapse, Image, Row, Typography } from 'antd';
import { List } from '../../ui';
import './Footer.less';
import { Logo } from '../../logo';
import { useMobile } from '../../../hooks/useMobile';
import { Link, useNavigate } from 'react-router-dom';
import { useApp } from 'src/components/app';
import { useAuth } from 'src/context/AuthProvider';
import { useLocation } from 'react-router';
import EmailIcon from '../../../assets/icons/email.svg';
import PhoneIcon from '../../../assets/icons/phone.svg';
import MobileLogo from '../../../assets/icons/mb_footer_logo.svg';

const year = new Date().getFullYear();

const Footer: FC = () => {
  const { auth } = useApp();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isMobile } = useMobile();
  const { email, phone_number, footer_collapse, payment_methods, policy } =
    useFooterQuery().data?.footer?.data?.attributes || {};

  const contacts = [
    { text: email, href: `mailto:${email}`, icon: EmailIcon },
    { text: phone_number, href: `tel:${phone_number}`, icon: PhoneIcon },
  ];

  const legalLinks =
    policy && policy?.length > 0
      ? policy.map((item) => {
          return { text: item?.title || '', href: item?.url || '' };
        })
      : [];

  const links =
    footer_collapse && footer_collapse.length > 0
      ? footer_collapse.map((el) => {
          return {
            title: el?.title,
            links: el?.item
              ? el?.item.map((el) => {
                  return { text: el?.title, href: el?.url || '' };
                })
              : [],
          };
        })
      : [];

  return (
    <Container dark id={'footer'} className={'footer-hm'} md={pathname === '/' && !isMobile}>
      {pathname === '/' && !isMobile ? (
        <div className={'homepage-footer-block'}>
          <div className={'homepage-footer'}>
            <Row justify={'space-between'} align={'middle'}>
              <Row>
                <div>
                  <Logo />
                  <div className={'homepage-footer-contacts'}>
                    <List
                      items={contacts}
                      renderItem={({ text, href, icon }) => (
                        <Fragment>
                          <Image preview={false} width={24} height={24} src={icon} />
                          <Typography.Link className="hm-contact-item contact-item" href={href}>
                            {text}
                          </Typography.Link>
                        </Fragment>
                      )}
                    />
                  </div>
                </div>
              </Row>
              <Row className={'hm-footer-menu'}>
                {links.map(({ title, links }) => (
                  <div key={title}>
                    <Typography.Text className={'title'}>{title}</Typography.Text>
                    <List
                      items={links}
                      renderItem={({ text, href }) => (
                        <>
                          {href === '/account' || href === '/orders' ? (
                            <p
                              style={{ cursor: 'pointer' }}
                              className={'link'}
                              onClick={() => (user ? navigate(href) : auth.modal.setOpen(true))}
                            >
                              {text}
                            </p>
                          ) : (
                            <Link to={href} className={'link'}>
                              {text}
                            </Link>
                          )}
                        </>
                      )}
                    />
                  </div>
                ))}
              </Row>
            </Row>
            <List
              isRow
              gap={16}
              className={'hm-payments payments'}
              items={
                (payment_methods as { icon: { data: { attributes: { url: string } } } }[]) || []
              }
              renderItem={({ icon }) => (
                <img
                  loading="lazy"
                  width={40}
                  height={24}
                  src={icon?.data?.attributes?.url}
                  className={'payment-method'}
                  alt={'icon'}
                />
              )}
            />
          </div>
          <div className={'hm-footer-info info'}>
            <List
              isRow
              items={legalLinks}
              className={'hm-footer-legal-links legal-links'}
              renderItem={({ text, href }) => (
                <Link to={href} className={'link'}>
                  {text}
                </Link>
              )}
            />

            <Typography.Text className={'link'}>
              {`Copyright ©️ ${year} Build Higher`}
            </Typography.Text>
          </div>
        </div>
      ) : (
        <Fragment>
          <div className={'first-row'}>
            <div className={'contacts'}>
              <Logo logo={MobileLogo} />

              <List
                className={`${isMobile ? 'mobile-contact-list' : ''}`}
                items={contacts}
                renderItem={({ text, href }) => (
                  <Typography.Link className={'contact-item'} href={href}>
                    {text}
                  </Typography.Link>
                )}
              />
            </div>

            <div className={'links'}>
              {isMobile ? (
                <Collapse
                  expandIconPosition={'end'}
                  items={links.map(({ links, title }) => ({
                    label: <Typography.Text className={'title'}>{title}</Typography.Text>,
                    children: (
                      <List
                        items={links}
                        renderItem={({ text, href }) => (
                          <>
                            {href === '/account' || href === '/orders' ? (
                              <p
                                style={{ cursor: 'pointer' }}
                                className={'link'}
                                onClick={() => (user ? navigate(href) : auth.modal.setOpen(true))}
                              >
                                {text}
                              </p>
                            ) : (
                              <Link to={href} className={'link'}>
                                {text}
                              </Link>
                            )}
                          </>
                        )}
                      />
                    ),
                  }))}
                  ghost
                />
              ) : (
                links.map(({ title, links }) => (
                  <div key={title}>
                    <Typography.Text className={'title'}>{title}</Typography.Text>
                    <List
                      items={links}
                      renderItem={({ text, href }) => (
                        <>
                          {href === '/account' || href === '/orders' ? (
                            <p
                              style={{ cursor: 'pointer' }}
                              className={'link'}
                              onClick={() => (user ? navigate(href) : auth.modal.setOpen(true))}
                            >
                              {text}
                            </p>
                          ) : (
                            <Link to={href} className={'link'}>
                              {text}
                            </Link>
                          )}
                        </>
                      )}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
          <div className={'second-row'}>
            <div className={'info'}>
              <List
                isRow
                items={legalLinks}
                className={'legal-links'}
                renderItem={({ text, href }) => (
                  <Link to={href} className={'link'}>
                    {text}
                  </Link>
                )}
              />

              <Typography.Text className={'link'}>
                {`Copyright ©️ ${year} Build Higher`}
              </Typography.Text>
            </div>

            <List
              isRow
              gap={16}
              className={'payments'}
              items={
                (payment_methods as { icon: { data: { attributes: { url: string } } } }[]) || []
              }
              renderItem={({ icon }) => (
                <img
                  loading="lazy"
                  width={40}
                  height={24}
                  src={icon?.data?.attributes?.url}
                  className={'payment-method'}
                  alt={'icon'}
                />
              )}
            />
          </div>
        </Fragment>
      )}
    </Container>
  );
};

export { Footer };
