const TOKEN_NAME = 'auth-token';

export const getAuthToken = (): string | null => {
  return localStorage.getItem(TOKEN_NAME);
};

export const setAuthToken = (token: string): void => {
  localStorage.setItem(TOKEN_NAME, token);
};

export const removeAuthToken = (): void => {
  localStorage.removeItem(TOKEN_NAME);
};
