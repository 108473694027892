import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useLoginMutation, useMeLazyQuery, useMeQuery, useRegisterMutation } from '../graphql';
import { getAuthToken, removeAuthToken, setAuthToken } from '../helpers/auth';
import { useApp } from 'src/components/app';

export type LoginFields = 'email' | 'password';
export type RegistrationFields = 'username' | 'email' | 'password';

type AuthProviderLogin = (fields: Record<LoginFields, string>) => Promise<void>;
type AuthProviderRegistration = (fields: Record<RegistrationFields, string>) => Promise<void>;

type AuthContextProps = {
  user: MeQuery['me'];
  login: AuthProviderLogin;
  register: AuthProviderRegistration;
  logout: () => Promise<void>;
  getUser: () => void;
};

type AuthProviderProps = PropsWithChildren<Partial<AuthContextProps>>;

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [getMe, { data: me, error }] = useMeLazyQuery({ fetchPolicy: 'no-cache' });

  const [loginMutation] = useLoginMutation();
  const [registerMutation] = useRegisterMutation();

  const user = error ? null : me?.me;

  useEffect(() => {
    getMe();
  }, []);

  const login = async ({ email, password }: Record<LoginFields, string>) => {
    await loginMutation({
      variables: {
        input: {
          identifier: email,
          password: password,
        },
      },
    }).then((result) => {
      if (result.data?.login.jwt) {
        setAuthToken(result.data.login.jwt);
        getMe({ context: { headers: { authorization: `Bearer ${getAuthToken()}` } } });
      }
    });
  };

  const register = async ({ email, username, password }: Record<RegistrationFields, string>) => {
    await registerMutation({
      variables: {
        input: { email, password, name: username },
      },
    }).then((result) => {
      if (result?.data?.register?.user?.email) {
        localStorage.setItem('email', result.data.register.user.email);
        getMe({ context: { headers: { authorization: `Bearer ${getAuthToken()}` } } });
      }
    });
  };

  const logout = async () => {
    removeAuthToken();
    getMe({ context: { headers: { authorization: undefined } } });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        register,
        getUser: () => { getMe() }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

