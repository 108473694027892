import { Button, Typography } from 'antd';
import successIllustration from 'src/assets/images/payment/success-illustration.svg';
import { useApp } from 'src/components/app';
import { useAuth } from 'src/context/AuthProvider';
import { useModalContext } from 'src/context/ModalProvider';

export const RequestSuccessful: React.FC = () => {
  const { closeModal, meta } = useModalContext();
  const { auth } = useApp();
  const { user } = useAuth();
  return (
    <div className={'request-successful'}>
      <img
        className={'illustration'}
        src={successIllustration}
        alt=""
        style={{ width: '111px', display: 'block' }}
      />
      <Typography.Text>{meta.message}</Typography.Text>

      {!user?.id && (
        <Button
          onClick={() => {
            auth.modal.setOpen(true);
            closeModal();
          }}
        >
          Log in
        </Button>
      )}
    </div>
  );
};
