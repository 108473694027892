import { FC, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import './MenuItem.less';
import { SvgIcon } from 'src/components/icons/SvgIcon';
import { Typography } from 'antd';

type Props = {
  iconType?: Maybe<string>;
  path?: Maybe<string>;
  isLink?: boolean;
  onClick?: () => void;
  title?: string;
};

export const MenuItem: FC<Props> = ({ iconType, path, isLink, title, onClick }) => {
  return (
    <Fragment>
      {isLink ? (
        <li className={'menu-item'}>
          <NavLink to={path || ''} className={'menu-link'}>
            <SvgIcon className={'menu-icon'} type={iconType as any} />
            <Typography.Text>{title}</Typography.Text>
          </NavLink>
        </li>
      ) : (
        <li className={'menu-list-item'} onClick={onClick}>
          <div className={'menu-item'}>
            <SvgIcon className={'menu-icon'} type={iconType as any} />
          </div>
          {title && <Typography.Text className={'menu_item_title'}>{title}</Typography.Text>}
        </li>
      )}
    </Fragment>
  );
};
