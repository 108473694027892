import { AuthContainer } from './ui/AuthContainer';
import { LoginForm } from './ui/LoginForm';
import { RegistrationForm } from './ui/RegistrationForm';

import { useState } from 'react';

import { useApp } from 'src/components/app';
import { PasswordRecover } from './ui/PasswordRecover';
import { SuccessRegistration } from './ui/SuccessRegistration';
import './index.less';

export type FormType = 'login' | 'registration' | 'success-registration' | 'password-recover';

export const Auth: React.FC = () => {
  const { auth } = useApp();
  const [formType, setFormType] = useState<FormType>('login');

  const titles: Record<FormType, string> = {
    login: 'Login',
    registration: 'Registration',
    'success-registration': '',
    'password-recover': 'Password recover',
  };

  const forms: Record<FormType, React.ReactNode> = {
    login: <LoginForm setFormType={setFormType} />,
    registration: <RegistrationForm setFormType={setFormType} />,
    'success-registration': <SuccessRegistration setFormType={setFormType} />,
    'password-recover': <PasswordRecover onCancel={() => setFormType('login')} />,
  };

  return (
    <AuthContainer
      title={titles[formType]}
      open={auth.modal.open}
      setOpen={auth.modal.setOpen}
      setFormType={setFormType}
    >
      {forms[formType]}
    </AuthContainer>
  );
};
