import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { ConfigProvider } from 'antd';
import { createContext, memo, useContext, type FC, type PropsWithChildren, useState } from 'react';
import introspection from '../../graphql';
import theme from '../../themes';
import '../../styles/main.less';
import Router from '../../pages';
import { AuthProvider } from 'src/context/AuthProvider';
import { ModalProvider } from 'src/context/ModalProvider';
import { iCartItem, iOrder } from '../../types';
import { getAuthToken, removeAuthToken } from 'src/helpers/auth';
import { Modals } from 'src/new-components/Modals';

type ContextProps = {
  app: {
    modalTitle: string;
    setModaTitle: React.Dispatch<React.SetStateAction<string>>;
    isOpenCart: boolean;
    setIsOpenCart: (value: boolean) => void;
    checkoutOrder: { id: string | null; products: iOrder[] } | null;
    setCheckoutOrder: Function;
    cartItem: iCartItem | null;
    setCartItem: Function;
    cartOrdersLength: number;
    setCartOrdersLength: React.Dispatch<React.SetStateAction<number>>;
  };
  auth: {
    modal: {
      open: boolean;
      setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    };
  };
};

const app: ContextProps['app'] = {
  modalTitle: '',
  setModaTitle: () => 'false',
  isOpenCart: false,
  setIsOpenCart: () => {},
  checkoutOrder: null,
  setCheckoutOrder: () => {},
  cartItem: null,
  setCartItem: () => {},
  cartOrdersLength: 0,
  setCartOrdersLength: () => {},
};

const auth: ContextProps['auth'] = {
  modal: {
    open: false,
    setOpen: () => false,
  },
};

const Context = createContext({ app, auth });

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  const [modalTitle, setModaTitle] = useState('');
  const [isOpenCart, setIsOpenCart] = useState(false);
  const [cartOrdersLength, setCartOrdersLength] = useState(0);
  const [checkoutOrder, setCheckoutOrder] = useState<{
    id: string | null;
    products: iOrder[];
  } | null>(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [cartItem, setCartItem] = useState<iCartItem | null>(null);

  return (
    <Context.Provider
      value={{
        app: {
          ...props.app,
          modalTitle,
          setModaTitle,
          isOpenCart,
          setIsOpenCart,
          checkoutOrder,
          setCheckoutOrder,
          cartItem,
          setCartItem,
          cartOrdersLength,
          setCartOrdersLength,
        },
        auth: {
          ...props.auth,
          modal: {
            open: isAuthModalOpen,
            setOpen: setIsAuthModalOpen,
          },
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useApp: () => ContextProps = () => useContext(Context);

const httpLink = createHttpLink({
  uri: `${import.meta.env.WEBSITE_API_URL ?? '/graphql'}`,
  credentials: 'same-origin',
});

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

const errorLink = onError(({ networkError }) => {
  if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
    removeAuthToken();
    window.location.href = '/';
  }
});

const client = new ApolloClient({
  // link: authLink.concat(httpLink),
  link: from([errorLink, authLink, httpLink]),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
});

const App: FC = memo(() => (
  <ApolloProvider client={client}>
    <AuthProvider>
      <ContextProvider app={app} auth={auth}>
        <ModalProvider>
          <ConfigProvider theme={theme}>
            <Router />
            <Modals />
          </ConfigProvider>
        </ModalProvider>
      </ContextProvider>
    </AuthProvider>
  </ApolloProvider>
));

export { useApp };

export default App;
