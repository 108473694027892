import './Cart.less';
import { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Col, Drawer, Empty, Modal, Row, Spin, Typography, notification } from 'antd';
import { useApp } from '../../app';
import CustomButton from '../../ui/CustomButton/CustomButton';
import { ReactComponent as CloseSVG } from '../../../assets/icons/close.svg';
import {
  useDeleteOrderMutation,
  useOrdersQuery,
  usePricePolicyOptionsQuery,
  useSampleOrderLimitsQuery,
  useUpdateOrderMutation,
  useUpdateSampleOrderLimitMutation,
} from '../../../graphql';
import { useAuth } from '../../../context/AuthProvider';
import OrderItem from '../OrderItem/OrderItem';
import { formatPrice } from '../../../helpers/formatPrice';
import { iOrder } from '../../../types';
import { useNavigate } from 'react-router';
import { formatOrderData } from '../../../helpers/formatOrderData';
import { getPriceAccodingToPricePolicy } from 'src/helpers/findPricePolicy';
import {
  checkSampleCustomBeforeDeleting,
  checkSampleCustomInArr,
} from 'src/helpers/checkSampleCustomInArr';

interface iCartBodyProps {
  data: iOrder[];
  setData: (value: iOrder[]) => void;
}
interface iCartFooterProps {
  onClickCheckout: () => void;
  totalPrice?: number;
  emptyOrders: boolean;
}

const CartFooter: FC<iCartFooterProps> = ({ totalPrice, onClickCheckout, emptyOrders }) => {
  const navigator = useNavigate();
  const { setIsOpenCart } = useApp().app;

  const toNewCones = () => {
    setIsOpenCart(false);
    navigator('/shop');
  };

  return (
    <Col className={'footer-cart'}>
      <Row className={'cart-total'} justify={'space-between'}>
        <Typography.Text style={{ color: '#888698' }}>Total Price</Typography.Text>
        <Typography.Text>${totalPrice ? formatPrice(totalPrice) : 0}</Typography.Text>
      </Row>
      <Row className={'cart-navigation'} wrap={false}>
        <CustomButton
          label={'ADD NEW CONES'}
          type={'dark'}
          onClick={toNewCones}
          className={'footer-cart__add-cones'}
        />
        <CustomButton
          label={'CHECKOUT'}
          className={'confirm'}
          onClick={onClickCheckout}
          disabled={emptyOrders}
        />
      </Row>
    </Col>
  );
};

const CartBody: FC<iCartBodyProps> = ({ data, setData }) => {
  const [deleteOrder] = useDeleteOrderMutation();
  const [updateOrder] = useUpdateOrderMutation();
  const { user } = useAuth();
  const { data: sampleOrderLimits, refetch: refetchSamplesLimit } = useSampleOrderLimitsQuery({
    variables: {
      id: user?.id || '',
    },
    fetchPolicy: 'network-only',
  });
  const [updateSampleLimit] = useUpdateSampleOrderLimitMutation();

  const limit = sampleOrderLimits?.sampleOrderLimits?.data[0].attributes?.maxCustomSamples || 0;
  const sampleOrderLimitId = sampleOrderLimits?.sampleOrderLimits?.data[0].id;

  // get price policy for PreRolledCones and for ConfiguratorCones and extract values
  const { data: pricePolicyOptionsData } = usePricePolicyOptionsQuery();

  const onDelete = async ({ id, isSample }: { id: string; isSample: boolean }) => {
    try {
      if (!isSample) {
        const { ableToDelete, type } = checkSampleCustomBeforeDeleting(data, id);
        if (!ableToDelete) {
          notification.error({
            message: `Remove the corresponding Sample ${type} first!`,
            description: `Samples should not be more than paid custom ${type} items in the cart.`,
            duration: 15,
            placement: 'top',
          });
          return;
        }
      }
      await deleteOrder({ variables: { id } });
      if (isSample) {
        await updateSampleLimit({
          variables: {
            id: sampleOrderLimitId || '',
            input: { maxCustomSamples: limit + 1 },
          },
        });
        refetchSamplesLimit();
      }
      setData(data?.filter((item) => item.id !== id));
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Error!',
        description: 'Please try later',
        duration: 4,
        placement: 'top',
      });
    }
  };

  const onUpdate = async ({ count, orderForChange }: { count: number; orderForChange: iOrder }) => {
    const pricePolicyPreRolledCones = pricePolicyOptionsData?.preRolledPage?.data?.attributes;
    const pricePolicyConfiguratorCones = pricePolicyOptionsData?.types?.data.find(
      (type) => type.attributes?.name === orderForChange.attributes?.type?.data?.attributes?.name
    )?.attributes;

    if (!pricePolicyPreRolledCones || !pricePolicyConfiguratorCones) {
      return;
    }
    // check price policy
    const price = getPriceAccodingToPricePolicy(
      orderForChange,
      pricePolicyPreRolledCones,
      pricePolicyConfiguratorCones,
      count
    );

    if (orderForChange.id) {
      await updateOrder({ variables: { id: orderForChange.id, input: { count } } });

      setData(
        data?.map((item) =>
          item.id !== orderForChange.id
            ? item
            : {
                ...item,
                attributes: {
                  ...item.attributes,
                  count,
                  price,
                },
              }
        )
      );
    }
  };

  return (
    <div className={'cart-items'}>
      {data?.map((item) => {
        const secondaryItemPreview =
          'https://bn-dev.fra1.digitaloceanspaces.com/app/build-higher/uploads/Frame_904_67b404b81c.png';
        const orderData = item?.attributes?.size?.data?.id ? formatOrderData(item) : null;
        return (
          <OrderItem
            key={item.id}
            id={item.id}
            typeId={item.attributes?.type?.data?.id}
            subTypeId={item.attributes?.sub_type?.data?.id}
            name={
              orderData ? orderData.name : item?.attributes?.paper_option?.data?.attributes?.name!
            }
            img={item.attributes?.cartPreview?.data?.attributes?.url ?? secondaryItemPreview}
            onDelete={() =>
              onDelete({
                id: item.id || '',
                isSample: item.attributes?.isSample || false,
              })
            }
            totalPrice={item.attributes?.count! * item.attributes?.price!}
            count={item.attributes?.count!}
            cones={item?.attributes?.conesPerBox!}
            onChangeCount={(count) => onUpdate({ count, orderForChange: item })}
            collapseProps={orderData ? orderData.collapseData : undefined}
            frontLogo={item?.attributes?.frontLogo}
            backLogo={item?.attributes?.backLogo}
            isPreRolled={item.attributes?.isPreRolled}
            isSample={item.attributes?.isSample}
            paperOptionName={item.attributes?.paper_option?.data?.attributes?.name}
            filterColor={item.attributes?.color}
            minBoxesOrder={item.attributes?.size?.data?.attributes?.minBoxesOrder || 1}
            preRolledSize={`${item.attributes?.preRolledSize?.data?.attributes?.size}g (${item.attributes?.preRolledSize?.data?.attributes?.dimension})`}
          />
        );
      })}
    </div>
  );
};

export const Cart: FC = () => {
  const navigator = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 440px)' });
  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const {
    app: { isOpenCart, setIsOpenCart, setCheckoutOrder, setCartOrdersLength },
  } = useApp();
  const { user } = useAuth();

  const [orders, setOrders] = useState<iOrder[]>([]);

  const { data, loading } = useOrdersQuery({
    variables: {
      filters: {
        users_permissions_user: { id: { eq: user?.id! } },
        cart: { id: { eq: user?.cart?.data?.id } },
        shipment_order: { id: { eq: null } },
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setOrders(data?.orders?.data!);
  }, [data]);

  useEffect(() => {
    setCartOrdersLength(orders?.length!);
  }, [orders]);

  const totalPrice = orders?.reduce((sum, { attributes }) => {
    const price = attributes?.price ?? 0;
    const count = attributes?.count ?? 1;

    return sum + price * count;
  }, 0);

  const onClickCheckout = () => {
    const checkResult = checkSampleCustomInArr(orders || []);

    if (checkResult.areMoreSamplesThanCustomCones) {
      notificationApi.error({
        message: 'Too many custom Samples in the cart!',
        description:
          'You have too many custom Samples in the cart, please delete them and continue ordering. There should be no more Samples custom items than paid custom items.',
        duration: 30,
        placement: 'top',
      });
      return;
    }

    if (checkResult.isOnlySamplesInArr) {
      notificationApi.error({
        message: 'You can not order only samples!',
        description:
          'Samples can be ordered only in conjunction with paid custom products. Please remove Samples from cart or add to card custom cones from configurator page',
        duration: 30,
        placement: 'top',
      });
      return;
    }

    setIsOpenCart(false);
    setCheckoutOrder({ id: null, products: orders });
    navigator('/checkout');
  };

  return !isMobile ? (
    <Drawer
      open={isOpenCart}
      onClose={() => setIsOpenCart(!isOpenCart)}
      className={'cart-drawer'}
      closeIcon={false}
      title={
        <Row justify={'space-between'} wrap={false}>
          <Typography.Title level={3} style={{ margin: 0 }}>
            Cart
          </Typography.Title>
          <CustomButton
            onClick={() => setIsOpenCart(!isOpenCart)}
            type={'link'}
            className={'close-btn'}
            label={<CloseSVG />}
          />
        </Row>
      }
      footer={
        <CartFooter
          totalPrice={totalPrice}
          emptyOrders={!orders?.length}
          onClickCheckout={onClickCheckout}
        />
      }
    >
      {notificationContextHolder}
      {orders && orders.length > 0 ? (
        <CartBody data={orders} setData={setOrders} />
      ) : loading ? (
        <Spin
          size="large"
          style={{ paddingTop: '120px', display: 'flex', justifyContent: 'center' }}
        />
      ) : (
        <Empty />
      )}
    </Drawer>
  ) : (
    <Modal
      open={isOpenCart}
      className={'cart-modal'}
      title={<Typography.Title level={3}>CART</Typography.Title>}
      onCancel={() => setIsOpenCart(false)}
      style={{
        width: '100%',
        top: 0,
        height: 'inherit',
        margin: 0,
        maxWidth: 'fit-content',
      }}
      zIndex={1001}
      footer={
        <CartFooter
          totalPrice={totalPrice}
          emptyOrders={!orders?.length}
          onClickCheckout={onClickCheckout}
        />
      }
    >
      {notificationContextHolder}
      {orders && orders.length > 0 ? (
        <CartBody data={orders} setData={setOrders} />
      ) : loading ? (
        <Spin
          size="large"
          style={{ paddingTop: '120px', display: 'flex', justifyContent: 'center' }}
        />
      ) : (
        <Empty />
      )}
    </Modal>
  );
};
