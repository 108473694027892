import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "AboutUsPage",
      "Cart",
      "Checkout",
      "CheckoutContactDetail",
      "ComponentDataContactInfo",
      "ComponentDataDimensions",
      "ComponentDataEntry",
      "ComponentDataPresentation",
      "ComponentDataPricePolicy",
      "ComponentDataSet",
      "ComponentLogoBackLogo",
      "ComponentLogoFrontLogo",
      "ComponentUiAboutUs",
      "ComponentUiCard",
      "ComponentUiCollapse",
      "ComponentUiConeDetail",
      "ComponentUiDocumentCard",
      "ComponentUiDocuments",
      "ComponentUiFaq",
      "ComponentUiFeatures",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiHomePageContactUs",
      "ComponentUiHomePageFeature",
      "ComponentUiLink",
      "ComponentUiNotationList",
      "ComponentUiParagraph",
      "ComponentUiPartnerList",
      "ComponentUiQuestions",
      "ComponentUiSection",
      "ComponentUiSectionWithCards",
      "ComponentUiSectionWithMedia",
      "ComponentUiTab",
      "ComponentUiText",
      "ComponentUiTopViewOfTheFilter",
      "ConeOption",
      "ConeType",
      "Configurator",
      "ConfiguratorFormRequest",
      "ContactFormRequest",
      "ContactInfo",
      "ContactPage",
      "Cta",
      "DeletedAccount",
      "Faq",
      "Filter",
      "Footer",
      "Home",
      "HomePageStandart",
      "Layout",
      "Order",
      "Paper",
      "PaperOption",
      "PaperVariety",
      "PreRolledPage",
      "PreRolledSize",
      "PrivacyPolicy",
      "ReactIconsIconlibrary",
      "Review",
      "SampleOrderLimit",
      "ShipmentOrder",
      "ShippingPolicy",
      "Size",
      "StripeCustomer",
      "SubType",
      "TermsOfUse",
      "Type",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "AboutUsPage",
      "Cart",
      "Checkout",
      "CheckoutContactDetail",
      "ComponentDataContactInfo",
      "ComponentDataDimensions",
      "ComponentDataEntry",
      "ComponentDataPresentation",
      "ComponentDataPricePolicy",
      "ComponentDataSet",
      "ComponentLogoBackLogo",
      "ComponentLogoFrontLogo",
      "ComponentUiAboutUs",
      "ComponentUiCard",
      "ComponentUiCollapse",
      "ComponentUiConeDetail",
      "ComponentUiDocumentCard",
      "ComponentUiDocuments",
      "ComponentUiFaq",
      "ComponentUiFeatures",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiHomePageContactUs",
      "ComponentUiHomePageFeature",
      "ComponentUiLink",
      "ComponentUiNotationList",
      "ComponentUiParagraph",
      "ComponentUiPartnerList",
      "ComponentUiQuestions",
      "ComponentUiSection",
      "ComponentUiSectionWithCards",
      "ComponentUiSectionWithMedia",
      "ComponentUiTab",
      "ComponentUiText",
      "ComponentUiTopViewOfTheFilter",
      "ConeOption",
      "ConeType",
      "Configurator",
      "ConfiguratorFormRequest",
      "ContactFormRequest",
      "ContactInfo",
      "ContactPage",
      "Cta",
      "DeletedAccount",
      "Faq",
      "Filter",
      "Footer",
      "Home",
      "HomePageStandart",
      "Layout",
      "Order",
      "Paper",
      "PaperOption",
      "PaperVariety",
      "PreRolledPage",
      "PreRolledSize",
      "PrivacyPolicy",
      "ReactIconsIconlibrary",
      "Review",
      "SampleOrderLimit",
      "ShipmentOrder",
      "ShippingPolicy",
      "Size",
      "StripeCustomer",
      "SubType",
      "TermsOfUse",
      "Type",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
  icon {
    data {
      ...File
    }
  }
  iconType
}
    `;
export const CollapseFragmentDoc = gql`
    fragment Collapse on ComponentUiCollapse {
  id
  title
  item {
    ...Link
  }
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
  hero_notation_list {
    id
    text
    icon {
      data {
        id
        attributes {
          url
        }
      }
    }
  }
  bg_desktop {
    data {
      id
      attributes {
        url
      }
    }
  }
  bg_mobile {
    data {
      id
      attributes {
        url
      }
    }
  }
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const SectionWithCardsFragmentDoc = gql`
    fragment SectionWithCards on ComponentUiSectionWithCards {
  id
  section_headline {
    ...Headline
  }
  section_card {
    ...Card
  }
}
    `;
export const SectionWithMediaFragmentDoc = gql`
    fragment SectionWithMedia on ComponentUiSectionWithMedia {
  id
  title
  media_url
  media {
    data {
      ...File
    }
  }
}
    `;
export const ContactInfoFragmentDoc = gql`
    fragment ContactInfo on ComponentDataContactInfo {
  name
  phone
  email
  country
  region
  city
  address
  postCode
}
    `;
export const PaperOptionFragmentDoc = gql`
    fragment PaperOption on PaperOption {
  name
  description
  image {
    data {
      attributes {
        url
      }
    }
  }
}
    `;
export const PaperFragmentDoc = gql`
    fragment Paper on Paper {
  name
  color
  minBoxes
  image {
    data {
      attributes {
        url
      }
    }
  }
}
    `;
export const PaperVarietyFragmentDoc = gql`
    fragment PaperVariety on PaperVariety {
  name
  papers {
    data {
      id
      attributes {
        ...Paper
      }
    }
  }
}
    `;
export const SizeFragmentDoc = gql`
    fragment Size on Size {
  name
  isTop
  info
  hint
  minBoxesOrder
  description {
    key
    value
  }
  filters {
    data {
      id
      attributes {
        value
        isTop
      }
    }
  }
  tipPlugDimensions {
    id
    diameter
    length
    headLength
    stickLength
    stickDiameter
  }
  topViewOfTheFilter {
    whiteColor {
      data {
        attributes {
          url
        }
      }
    }
    blackColor {
      data {
        attributes {
          url
        }
      }
    }
  }
}
    `;
export const PricePolicyFragmentDoc = gql`
    fragment PricePolicy on ComponentDataPricePolicy {
  id
  conesFrom
  conesTo
  pricePerCone
}
    `;
export const TypeFragmentDoc = gql`
    fragment Type on TypeEntity {
  id
  attributes {
    name
    shippingCoefficient
    paper_options {
      data {
        id
        attributes {
          ...PaperOption
        }
      }
    }
    paper_varieties {
      data {
        id
        attributes {
          ...PaperVariety
        }
      }
    }
    sizes {
      data {
        id
        attributes {
          ...Size
        }
      }
    }
    image {
      data {
        ...File
      }
    }
    isTop
    pricePolicy {
      ...PricePolicy
    }
    showRequestForm
    sub_types {
      data {
        id
        attributes {
          name
          image {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const UsersPermissionUserFragmentDoc = gql`
    fragment UsersPermissionUser on UsersPermissionsUserEntity {
  id
  attributes {
    id
    email
  }
}
    `;
export const FrontLogoFragmentDoc = gql`
    fragment FrontLogo on ComponentLogoFrontLogo {
  id
  angle
  logoWidth
  logo {
    data {
      ...File
    }
  }
}
    `;
export const BackLogoFragmentDoc = gql`
    fragment BackLogo on ComponentLogoBackLogo {
  id
  angle
  text
  color
  logoWidth
  logo {
    data {
      ...File
    }
  }
}
    `;
export const FilterFragmentDoc = gql`
    fragment Filter on FilterEntity {
  id
  attributes {
    value
    sizes {
      data {
        id
        attributes {
          ...Size
        }
      }
    }
  }
}
    `;
export const OrderFragmentDoc = gql`
    fragment Order on OrderEntity {
  id
  attributes {
    type {
      data {
        ...Type
      }
    }
    size {
      data {
        attributes {
          ...Size
        }
      }
    }
    users_permissions_user {
      data {
        ...UsersPermissionUser
      }
    }
    color
    paper_option {
      data {
        id
        attributes {
          ...PaperOption
        }
      }
    }
    frontLogo {
      ...FrontLogo
    }
    backLogo {
      ...BackLogo
    }
    count
    price
    filter {
      data {
        ...Filter
      }
    }
    cartPreview {
      data {
        ...File
      }
    }
    conesPerBox
    isPreRolled
    isSample
    shipment_order {
      data {
        id
      }
    }
  }
}
    `;
export const ShipmentOrderFragmentDoc = gql`
    fragment ShipmentOrder on ShipmentOrderEntity {
  id
  attributes {
    ContactInfo {
      ...ContactInfo
    }
    shipmentType
    orders {
      data {
        ...Order
      }
    }
    client {
      data {
        ...UsersPermissionUser
      }
    }
    payed
    status
    via_invoice
    orderPrice
    shipmentPrice
    totalPrice
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const ConfirmOrderDocument = gql`
    mutation confirmOrder($input: ConfirmOrderInput!) {
  confirmOrder(input: $input) {
    orderId
  }
}
    `;
export type ConfirmOrderMutationFn = Apollo.MutationFunction<ConfirmOrderMutation, ConfirmOrderMutationVariables>;
export function useConfirmOrderMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmOrderMutation, ConfirmOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmOrderMutation, ConfirmOrderMutationVariables>(ConfirmOrderDocument, options);
      }
export type ConfirmOrderMutationHookResult = ReturnType<typeof useConfirmOrderMutation>;
export type ConfirmOrderMutationResult = Apollo.MutationResult<ConfirmOrderMutation>;
export const CreateCheckoutContactDetailDocument = gql`
    mutation createCheckoutContactDetail($input: CheckoutContactDetailInput!) {
  createCheckoutContactDetail(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateCheckoutContactDetailMutationFn = Apollo.MutationFunction<CreateCheckoutContactDetailMutation, CreateCheckoutContactDetailMutationVariables>;
export function useCreateCheckoutContactDetailMutation(baseOptions?: Apollo.MutationHookOptions<CreateCheckoutContactDetailMutation, CreateCheckoutContactDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCheckoutContactDetailMutation, CreateCheckoutContactDetailMutationVariables>(CreateCheckoutContactDetailDocument, options);
      }
export type CreateCheckoutContactDetailMutationHookResult = ReturnType<typeof useCreateCheckoutContactDetailMutation>;
export type CreateCheckoutContactDetailMutationResult = Apollo.MutationResult<CreateCheckoutContactDetailMutation>;
export const CreateOrderDocument = gql`
    mutation createOrder($input: OrderInput!) {
  createOrder(data: $input) {
    data {
      id
      attributes {
        color
        count
        conesPerBox
        price
        isFavorite
        isPreRolled
        isSample
        cartPreview {
          data {
            ...File
          }
        }
        filter {
          data {
            attributes {
              value
            }
          }
        }
        backLogo {
          ...BackLogo
        }
        frontLogo {
          ...FrontLogo
        }
        paper_option {
          data {
            id
            attributes {
              ...PaperOption
            }
          }
        }
        size {
          data {
            id
            attributes {
              ...Size
            }
          }
        }
        preRolledSize {
          data {
            id
            attributes {
              size
              dimension
            }
          }
        }
        users_permissions_user {
          data {
            id
          }
        }
        type {
          data {
            id
            attributes {
              name
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        sub_type {
          data {
            id
            attributes {
              name
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        shipment_order {
          data {
            id
            attributes {
              payed
              confirmationToken
              status
            }
          }
        }
        coneOption {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}
${BackLogoFragmentDoc}
${FrontLogoFragmentDoc}
${PaperOptionFragmentDoc}
${SizeFragmentDoc}`;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export const DeleteCheckoutContactDetailDocument = gql`
    mutation deleteCheckoutContactDetail($id: ID!) {
  deleteCheckoutContactDetail(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteCheckoutContactDetailMutationFn = Apollo.MutationFunction<DeleteCheckoutContactDetailMutation, DeleteCheckoutContactDetailMutationVariables>;
export function useDeleteCheckoutContactDetailMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCheckoutContactDetailMutation, DeleteCheckoutContactDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCheckoutContactDetailMutation, DeleteCheckoutContactDetailMutationVariables>(DeleteCheckoutContactDetailDocument, options);
      }
export type DeleteCheckoutContactDetailMutationHookResult = ReturnType<typeof useDeleteCheckoutContactDetailMutation>;
export type DeleteCheckoutContactDetailMutationResult = Apollo.MutationResult<DeleteCheckoutContactDetailMutation>;
export const DeleteOrderDocument = gql`
    mutation deleteOrder($id: ID!) {
  deleteOrder(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteOrderMutationFn = Apollo.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;
export function useDeleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, options);
      }
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export const DeleteShipmentOrderDocument = gql`
    mutation deleteShipmentOrder($id: ID!) {
  deleteShipmentOrder(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteShipmentOrderMutationFn = Apollo.MutationFunction<DeleteShipmentOrderMutation, DeleteShipmentOrderMutationVariables>;
export function useDeleteShipmentOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShipmentOrderMutation, DeleteShipmentOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteShipmentOrderMutation, DeleteShipmentOrderMutationVariables>(DeleteShipmentOrderDocument, options);
      }
export type DeleteShipmentOrderMutationHookResult = ReturnType<typeof useDeleteShipmentOrderMutation>;
export type DeleteShipmentOrderMutationResult = Apollo.MutationResult<DeleteShipmentOrderMutation>;
export const DeleteUploadFileDocument = gql`
    mutation DeleteUploadFile($id: ID!) {
  deleteUploadFile(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteUploadFileMutationFn = Apollo.MutationFunction<DeleteUploadFileMutation, DeleteUploadFileMutationVariables>;
export function useDeleteUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUploadFileMutation, DeleteUploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUploadFileMutation, DeleteUploadFileMutationVariables>(DeleteUploadFileDocument, options);
      }
export type DeleteUploadFileMutationHookResult = ReturnType<typeof useDeleteUploadFileMutation>;
export type DeleteUploadFileMutationResult = Apollo.MutationResult<DeleteUploadFileMutation>;
export const LoginDocument = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user {
      id
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export const RegisterDocument = gql`
    mutation register($input: RegisterInput!) {
  register(input: $input) {
    jwt
    user {
      id
      name
      email
      confirmed
      blocked
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export const ReorderDocument = gql`
    mutation reorder($input: ConfirmOrderInput!) {
  reorder(input: $input) {
    orderId
  }
}
    `;
export type ReorderMutationFn = Apollo.MutationFunction<ReorderMutation, ReorderMutationVariables>;
export function useReorderMutation(baseOptions?: Apollo.MutationHookOptions<ReorderMutation, ReorderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderMutation, ReorderMutationVariables>(ReorderDocument, options);
      }
export type ReorderMutationHookResult = ReturnType<typeof useReorderMutation>;
export type ReorderMutationResult = Apollo.MutationResult<ReorderMutation>;
export const UpdateCartDocument = gql`
    mutation updateCart($data: CartInput!, $id: ID!) {
  updateCart(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateCartMutationFn = Apollo.MutationFunction<UpdateCartMutation, UpdateCartMutationVariables>;
export function useUpdateCartMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCartMutation, UpdateCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCartMutation, UpdateCartMutationVariables>(UpdateCartDocument, options);
      }
export type UpdateCartMutationHookResult = ReturnType<typeof useUpdateCartMutation>;
export type UpdateCartMutationResult = Apollo.MutationResult<UpdateCartMutation>;
export const UpdateCheckoutContactDetailDocument = gql`
    mutation updateCheckoutContactDetail($input: CheckoutContactDetailInput!, $id: ID!) {
  updateCheckoutContactDetail(id: $id, data: $input) {
    data {
      id
    }
  }
}
    `;
export type UpdateCheckoutContactDetailMutationFn = Apollo.MutationFunction<UpdateCheckoutContactDetailMutation, UpdateCheckoutContactDetailMutationVariables>;
export function useUpdateCheckoutContactDetailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCheckoutContactDetailMutation, UpdateCheckoutContactDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCheckoutContactDetailMutation, UpdateCheckoutContactDetailMutationVariables>(UpdateCheckoutContactDetailDocument, options);
      }
export type UpdateCheckoutContactDetailMutationHookResult = ReturnType<typeof useUpdateCheckoutContactDetailMutation>;
export type UpdateCheckoutContactDetailMutationResult = Apollo.MutationResult<UpdateCheckoutContactDetailMutation>;
export const UpdateLogoPropsDocument = gql`
    mutation updateLogoProps($input: UpdateLogoPropsInput!) {
  updateLogoProps(input: $input) {
    orderId
  }
}
    `;
export type UpdateLogoPropsMutationFn = Apollo.MutationFunction<UpdateLogoPropsMutation, UpdateLogoPropsMutationVariables>;
export function useUpdateLogoPropsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLogoPropsMutation, UpdateLogoPropsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLogoPropsMutation, UpdateLogoPropsMutationVariables>(UpdateLogoPropsDocument, options);
      }
export type UpdateLogoPropsMutationHookResult = ReturnType<typeof useUpdateLogoPropsMutation>;
export type UpdateLogoPropsMutationResult = Apollo.MutationResult<UpdateLogoPropsMutation>;
export const UpdateOrderDocument = gql`
    mutation updateOrder($input: OrderInput!, $id: ID!) {
  updateOrder(id: $id, data: $input) {
    data {
      id
    }
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export const UpdateSampleOrderLimitDocument = gql`
    mutation updateSampleOrderLimit($input: SampleOrderLimitInput!, $id: ID!) {
  updateSampleOrderLimit(id: $id, data: $input) {
    data {
      id
    }
  }
}
    `;
export type UpdateSampleOrderLimitMutationFn = Apollo.MutationFunction<UpdateSampleOrderLimitMutation, UpdateSampleOrderLimitMutationVariables>;
export function useUpdateSampleOrderLimitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSampleOrderLimitMutation, UpdateSampleOrderLimitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSampleOrderLimitMutation, UpdateSampleOrderLimitMutationVariables>(UpdateSampleOrderLimitDocument, options);
      }
export type UpdateSampleOrderLimitMutationHookResult = ReturnType<typeof useUpdateSampleOrderLimitMutation>;
export type UpdateSampleOrderLimitMutationResult = Apollo.MutationResult<UpdateSampleOrderLimitMutation>;
export const UpdateUserDocument = gql`
    mutation updateUser($data: UsersPermissionsUserInput!, $id: ID!) {
  updateUsersPermissionsUser(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export const AboutPageDocument = gql`
    query aboutPage {
  aboutUsPage {
    data {
      attributes {
        aboutUs {
          title
          description {
            value
          }
          aboutCones
          cone_types {
            data {
              attributes {
                name
                mobileImg {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                desktopImg {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                coneDetail {
                  name
                  desc
                  number
                }
              }
            }
          }
        }
        coneSize
        trusted
        partners {
          data {
            attributes {
              url
            }
          }
        }
        conesDisplay {
          data {
            attributes {
              url
            }
          }
        }
        presentation {
          action
          description
          title
          pdf {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
}
    `;
export function useAboutPageQuery(baseOptions?: Apollo.QueryHookOptions<AboutPageQuery, AboutPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AboutPageQuery, AboutPageQueryVariables>(AboutPageDocument, options);
      }
export function useAboutPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AboutPageQuery, AboutPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AboutPageQuery, AboutPageQueryVariables>(AboutPageDocument, options);
        }
export type AboutPageQueryHookResult = ReturnType<typeof useAboutPageQuery>;
export type AboutPageLazyQueryHookResult = ReturnType<typeof useAboutPageLazyQuery>;
export type AboutPageQueryResult = Apollo.QueryResult<AboutPageQuery, AboutPageQueryVariables>;
export const CartDocument = gql`
    query cart($id: ID!) {
  cart(id: $id) {
    data {
      id
    }
  }
}
    `;
export function useCartQuery(baseOptions: Apollo.QueryHookOptions<CartQuery, CartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CartQuery, CartQueryVariables>(CartDocument, options);
      }
export function useCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(CartDocument, options);
        }
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const CheckoutDocument = gql`
    query Checkout {
  checkout {
    data {
      attributes {
        freeGroundShipping
        freePartialAir
        airShipping
        sampleGroundShipping
        titleGroundShipping
        titleCombinedShipping
        titleAirShipping
        titleSampleGroundShipping
      }
    }
  }
}
    `;
export function useCheckoutQuery(baseOptions?: Apollo.QueryHookOptions<CheckoutQuery, CheckoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, options);
      }
export function useCheckoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutQuery, CheckoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, options);
        }
export type CheckoutQueryHookResult = ReturnType<typeof useCheckoutQuery>;
export type CheckoutLazyQueryHookResult = ReturnType<typeof useCheckoutLazyQuery>;
export type CheckoutQueryResult = Apollo.QueryResult<CheckoutQuery, CheckoutQueryVariables>;
export const CheckoutContactDetailsDocument = gql`
    query checkoutContactDetails($filters: CheckoutContactDetailFiltersInput!) {
  checkoutContactDetails(filters: $filters) {
    data {
      id
      attributes {
        shipmentType
        contact_info {
          ...ContactInfo
        }
      }
    }
  }
}
    ${ContactInfoFragmentDoc}`;
export function useCheckoutContactDetailsQuery(baseOptions: Apollo.QueryHookOptions<CheckoutContactDetailsQuery, CheckoutContactDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckoutContactDetailsQuery, CheckoutContactDetailsQueryVariables>(CheckoutContactDetailsDocument, options);
      }
export function useCheckoutContactDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutContactDetailsQuery, CheckoutContactDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckoutContactDetailsQuery, CheckoutContactDetailsQueryVariables>(CheckoutContactDetailsDocument, options);
        }
export type CheckoutContactDetailsQueryHookResult = ReturnType<typeof useCheckoutContactDetailsQuery>;
export type CheckoutContactDetailsLazyQueryHookResult = ReturnType<typeof useCheckoutContactDetailsLazyQuery>;
export type CheckoutContactDetailsQueryResult = Apollo.QueryResult<CheckoutContactDetailsQuery, CheckoutContactDetailsQueryVariables>;
export const ConeOptionsDocument = gql`
    query coneOptions {
  coneOptions {
    data {
      id
      attributes {
        order
        name
        description
        actionText
        shipment
        href
        img {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export function useConeOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ConeOptionsQuery, ConeOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConeOptionsQuery, ConeOptionsQueryVariables>(ConeOptionsDocument, options);
      }
export function useConeOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConeOptionsQuery, ConeOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConeOptionsQuery, ConeOptionsQueryVariables>(ConeOptionsDocument, options);
        }
export type ConeOptionsQueryHookResult = ReturnType<typeof useConeOptionsQuery>;
export type ConeOptionsLazyQueryHookResult = ReturnType<typeof useConeOptionsLazyQuery>;
export type ConeOptionsQueryResult = Apollo.QueryResult<ConeOptionsQuery, ConeOptionsQueryVariables>;
export const ConfiguratorDocument = gql`
    query configurator {
  configurator {
    data {
      id
      attributes {
        sampleConesPerBox
        colors {
          ...Entry
        }
        backside_logo_text {
          ...Entry
        }
        filterColorBackground {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    ${EntryFragmentDoc}`;
export function useConfiguratorQuery(baseOptions?: Apollo.QueryHookOptions<ConfiguratorQuery, ConfiguratorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfiguratorQuery, ConfiguratorQueryVariables>(ConfiguratorDocument, options);
      }
export function useConfiguratorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfiguratorQuery, ConfiguratorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfiguratorQuery, ConfiguratorQueryVariables>(ConfiguratorDocument, options);
        }
export type ConfiguratorQueryHookResult = ReturnType<typeof useConfiguratorQuery>;
export type ConfiguratorLazyQueryHookResult = ReturnType<typeof useConfiguratorLazyQuery>;
export type ConfiguratorQueryResult = Apollo.QueryResult<ConfiguratorQuery, ConfiguratorQueryVariables>;
export const ContactInfoDocument = gql`
    query contactInfo {
  contactInfo {
    data {
      attributes {
        email
        messageSubject
      }
    }
  }
}
    `;
export function useContactInfoQuery(baseOptions?: Apollo.QueryHookOptions<ContactInfoQuery, ContactInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactInfoQuery, ContactInfoQueryVariables>(ContactInfoDocument, options);
      }
export function useContactInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactInfoQuery, ContactInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactInfoQuery, ContactInfoQueryVariables>(ContactInfoDocument, options);
        }
export type ContactInfoQueryHookResult = ReturnType<typeof useContactInfoQuery>;
export type ContactInfoLazyQueryHookResult = ReturnType<typeof useContactInfoLazyQuery>;
export type ContactInfoQueryResult = Apollo.QueryResult<ContactInfoQuery, ContactInfoQueryVariables>;
export const ContactPageDocument = gql`
    query contactPage {
  contactPage {
    data {
      id
      attributes {
        description
        address
        email
        phone
        createConeDesc
        createConeTitle
        location {
          data {
            ...File
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useContactPageQuery(baseOptions?: Apollo.QueryHookOptions<ContactPageQuery, ContactPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactPageQuery, ContactPageQueryVariables>(ContactPageDocument, options);
      }
export function useContactPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactPageQuery, ContactPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactPageQuery, ContactPageQueryVariables>(ContactPageDocument, options);
        }
export type ContactPageQueryHookResult = ReturnType<typeof useContactPageQuery>;
export type ContactPageLazyQueryHookResult = ReturnType<typeof useContactPageLazyQuery>;
export type ContactPageQueryResult = Apollo.QueryResult<ContactPageQuery, ContactPageQueryVariables>;
export const CtaDocument = gql`
    query cta {
  cta {
    data {
      attributes {
        title
        description
        btnText
        image {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export function useCtaQuery(baseOptions?: Apollo.QueryHookOptions<CtaQuery, CtaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CtaQuery, CtaQueryVariables>(CtaDocument, options);
      }
export function useCtaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CtaQuery, CtaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CtaQuery, CtaQueryVariables>(CtaDocument, options);
        }
export type CtaQueryHookResult = ReturnType<typeof useCtaQuery>;
export type CtaLazyQueryHookResult = ReturnType<typeof useCtaLazyQuery>;
export type CtaQueryResult = Apollo.QueryResult<CtaQuery, CtaQueryVariables>;
export const FaqDocument = gql`
    query faq {
  faqs {
    data {
      attributes {
        answer
        question
        list
        order
      }
    }
  }
}
    `;
export function useFaqQuery(baseOptions?: Apollo.QueryHookOptions<FaqQuery, FaqQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FaqQuery, FaqQueryVariables>(FaqDocument, options);
      }
export function useFaqLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaqQuery, FaqQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FaqQuery, FaqQueryVariables>(FaqDocument, options);
        }
export type FaqQueryHookResult = ReturnType<typeof useFaqQuery>;
export type FaqLazyQueryHookResult = ReturnType<typeof useFaqLazyQuery>;
export type FaqQueryResult = Apollo.QueryResult<FaqQuery, FaqQueryVariables>;
export const FooterDocument = gql`
    query footer {
  footer {
    data {
      attributes {
        email
        phone_number
        footer_collapse {
          ...Collapse
        }
        policy {
          ...Link
        }
        payment_methods {
          ...Link
        }
      }
    }
  }
}
    ${CollapseFragmentDoc}
${LinkFragmentDoc}
${FileFragmentDoc}`;
export function useFooterQuery(baseOptions?: Apollo.QueryHookOptions<FooterQuery, FooterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FooterQuery, FooterQueryVariables>(FooterDocument, options);
      }
export function useFooterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FooterQuery, FooterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FooterQuery, FooterQueryVariables>(FooterDocument, options);
        }
export type FooterQueryHookResult = ReturnType<typeof useFooterQuery>;
export type FooterLazyQueryHookResult = ReturnType<typeof useFooterLazyQuery>;
export type FooterQueryResult = Apollo.QueryResult<FooterQuery, FooterQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      id
      attributes {
        hero {
          ...Section
          buttonRep {
            ...Link
          }
        }
        partner_list {
          id
          title
          image_list {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
        home_standarts {
          ...Section
        }
        home_page_standarts {
          data {
            attributes {
              standart_image {
                data {
                  ...File
                }
              }
            }
          }
        }
        cone_options {
          ...SectionWithCards
        }
        home_page_features {
          title
          home_page_feature {
            name
            details
            icon {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
        home_page_description {
          ...SectionWithCards
        }
        how_it_works {
          ...SectionWithMedia
        }
        what_we_offer {
          ...SectionWithCards
          download_button {
            ...Link
          }
          pdf {
            data {
              attributes {
                url
              }
            }
          }
        }
        paper_options {
          ...SectionWithCards
        }
        branding_options {
          ...SectionWithCards
          download_button {
            ...Link
          }
          pdf {
            data {
              attributes {
                url
              }
            }
          }
        }
        faq {
          title
          questions {
            answer
            question
            list
          }
        }
        documents {
          documentCard {
            title
            btnText
            document {
              data {
                attributes {
                  url
                }
              }
            }
          }
          title
        }
        contact_us {
          title
          contact_form_title
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${FileFragmentDoc}
${CardFragmentDoc}
${SectionWithCardsFragmentDoc}
${HeadlineFragmentDoc}
${SectionWithMediaFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const LayoutDocument = gql`
    query layout {
  layout {
    data {
      id
      attributes {
        header_nav_authorized {
          ...Link
          iconType
        }
        header_nav_nonAuthorized {
          ...Link
          iconType
        }
        menu_nav_authorized {
          ...Link
          iconType
        }
        menu_nav_nonAuthorized {
          ...Link
          iconType
        }
        phone_number
      }
    }
  }
}
    ${LinkFragmentDoc}
${FileFragmentDoc}`;
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
      }
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
        }
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>;
export const LogoDocument = gql`
    query logo {
  layout {
    data {
      id
      attributes {
        logo {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export function useLogoQuery(baseOptions?: Apollo.QueryHookOptions<LogoQuery, LogoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LogoQuery, LogoQueryVariables>(LogoDocument, options);
      }
export function useLogoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogoQuery, LogoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LogoQuery, LogoQueryVariables>(LogoDocument, options);
        }
export type LogoQueryHookResult = ReturnType<typeof useLogoQuery>;
export type LogoLazyQueryHookResult = ReturnType<typeof useLogoLazyQuery>;
export type LogoQueryResult = Apollo.QueryResult<LogoQuery, LogoQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    email
    name
    phone
    country
    province
    city
    postCode
    address
    cart {
      data {
        id
      }
    }
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const OrdersDocument = gql`
    query orders($filters: OrderFiltersInput, $page: Int, $pageSize: Int) {
  orders(
    filters: $filters
    sort: ["createdAt:desc"]
    pagination: {page: $page, pageSize: $pageSize}
  ) {
    data {
      id
      attributes {
        color
        count
        conesPerBox
        price
        isFavorite
        isPreRolled
        isSample
        cartPreview {
          data {
            ...File
          }
        }
        filter {
          data {
            attributes {
              value
            }
          }
        }
        backLogo {
          ...BackLogo
        }
        frontLogo {
          ...FrontLogo
        }
        paper_option {
          data {
            id
            attributes {
              ...PaperOption
            }
          }
        }
        size {
          data {
            id
            attributes {
              ...Size
            }
          }
        }
        preRolledSize {
          data {
            id
            attributes {
              size
              dimension
            }
          }
        }
        users_permissions_user {
          data {
            id
          }
        }
        type {
          data {
            id
            attributes {
              name
              shippingCoefficient
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        sub_type {
          data {
            id
            attributes {
              name
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        shipment_order {
          data {
            id
            attributes {
              payed
              confirmationToken
              status
            }
          }
        }
        coneOption {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
    meta {
      pagination {
        page
        pageSize
        pageCount
        total
      }
    }
  }
}
    ${FileFragmentDoc}
${BackLogoFragmentDoc}
${FrontLogoFragmentDoc}
${PaperOptionFragmentDoc}
${SizeFragmentDoc}`;
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const PrerolledSampleDocument = gql`
    query prerolledSample {
  paperOptions {
    data {
      id
      attributes {
        name
        details
        description
        image {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
  preRolledSizes {
    data {
      id
      attributes {
        size
        dimension
        countPerOneTower
        minBoxesOrder
      }
    }
  }
  preRolledPage {
    data {
      id
      attributes {
        sampleConesPerTower
        sampleShippingPrice
        paperOptionsWithInfo {
          data {
            id
            attributes {
              name
              details
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        img {
          data {
            id
            attributes {
              url
            }
          }
        }
        preview_image {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export function usePrerolledSampleQuery(baseOptions?: Apollo.QueryHookOptions<PrerolledSampleQuery, PrerolledSampleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrerolledSampleQuery, PrerolledSampleQueryVariables>(PrerolledSampleDocument, options);
      }
export function usePrerolledSampleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrerolledSampleQuery, PrerolledSampleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrerolledSampleQuery, PrerolledSampleQueryVariables>(PrerolledSampleDocument, options);
        }
export type PrerolledSampleQueryHookResult = ReturnType<typeof usePrerolledSampleQuery>;
export type PrerolledSampleLazyQueryHookResult = ReturnType<typeof usePrerolledSampleLazyQuery>;
export type PrerolledSampleQueryResult = Apollo.QueryResult<PrerolledSampleQuery, PrerolledSampleQueryVariables>;
export const PrerolledDocument = gql`
    query prerolled {
  paperOptions {
    data {
      id
      attributes {
        name
        details
        description
        image {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
  preRolledSizes {
    data {
      id
      attributes {
        size
        dimension
        countPerOneTower
        minBoxesOrder
      }
    }
  }
  preRolledPage {
    data {
      id
      attributes {
        pricePolicy {
          ...PricePolicy
        }
        paperOptionsWithInfo {
          data {
            id
            attributes {
              name
              details
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        img {
          data {
            id
            attributes {
              url
            }
          }
        }
        preview_image {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    ${PricePolicyFragmentDoc}`;
export function usePrerolledQuery(baseOptions?: Apollo.QueryHookOptions<PrerolledQuery, PrerolledQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrerolledQuery, PrerolledQueryVariables>(PrerolledDocument, options);
      }
export function usePrerolledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrerolledQuery, PrerolledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrerolledQuery, PrerolledQueryVariables>(PrerolledDocument, options);
        }
export type PrerolledQueryHookResult = ReturnType<typeof usePrerolledQuery>;
export type PrerolledLazyQueryHookResult = ReturnType<typeof usePrerolledLazyQuery>;
export type PrerolledQueryResult = Apollo.QueryResult<PrerolledQuery, PrerolledQueryVariables>;
export const PricePolicyOptionsDocument = gql`
    query pricePolicyOptions {
  preRolledPage {
    data {
      id
      attributes {
        pricePolicy {
          ...PricePolicy
        }
      }
    }
  }
  types {
    data {
      id
      attributes {
        name
        isTop
        pricePolicy {
          ...PricePolicy
        }
      }
    }
  }
}
    ${PricePolicyFragmentDoc}`;
export function usePricePolicyOptionsQuery(baseOptions?: Apollo.QueryHookOptions<PricePolicyOptionsQuery, PricePolicyOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PricePolicyOptionsQuery, PricePolicyOptionsQueryVariables>(PricePolicyOptionsDocument, options);
      }
export function usePricePolicyOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PricePolicyOptionsQuery, PricePolicyOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PricePolicyOptionsQuery, PricePolicyOptionsQueryVariables>(PricePolicyOptionsDocument, options);
        }
export type PricePolicyOptionsQueryHookResult = ReturnType<typeof usePricePolicyOptionsQuery>;
export type PricePolicyOptionsLazyQueryHookResult = ReturnType<typeof usePricePolicyOptionsLazyQuery>;
export type PricePolicyOptionsQueryResult = Apollo.QueryResult<PricePolicyOptionsQuery, PricePolicyOptionsQueryVariables>;
export const PrivacyPolicyDocument = gql`
    query privacyPolicy {
  privacyPolicy {
    data {
      attributes {
        text
      }
    }
  }
}
    `;
export function usePrivacyPolicyQuery(baseOptions?: Apollo.QueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
      }
export function usePrivacyPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
        }
export type PrivacyPolicyQueryHookResult = ReturnType<typeof usePrivacyPolicyQuery>;
export type PrivacyPolicyLazyQueryHookResult = ReturnType<typeof usePrivacyPolicyLazyQuery>;
export type PrivacyPolicyQueryResult = Apollo.QueryResult<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>;
export const ProductDocument = gql`
    query product($typeId: ID!, $paperOptionId: ID!, $sizeId: ID!, $paperId: ID!, $filterId: ID!) {
  type(id: $typeId) {
    data {
      id
      attributes {
        name
        image {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
  paperOption(id: $paperOptionId) {
    data {
      id
      attributes {
        ...PaperOption
      }
    }
  }
  size(id: $sizeId) {
    data {
      id
      attributes {
        ...Size
      }
    }
  }
  filter(id: $filterId) {
    data {
      id
      attributes {
        value
      }
    }
  }
  paper(id: $paperId) {
    data {
      id
      attributes {
        ...Paper
      }
    }
  }
}
    ${PaperOptionFragmentDoc}
${SizeFragmentDoc}
${PaperFragmentDoc}`;
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const SampleOrderLimitsDocument = gql`
    query sampleOrderLimits($id: ID!) {
  sampleOrderLimits(filters: {users_permissions_user: {id: {eq: $id}}}) {
    data {
      id
      attributes {
        maxBlankSamples
        maxCustomSamples
      }
    }
  }
}
    `;
export function useSampleOrderLimitsQuery(baseOptions: Apollo.QueryHookOptions<SampleOrderLimitsQuery, SampleOrderLimitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SampleOrderLimitsQuery, SampleOrderLimitsQueryVariables>(SampleOrderLimitsDocument, options);
      }
export function useSampleOrderLimitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SampleOrderLimitsQuery, SampleOrderLimitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SampleOrderLimitsQuery, SampleOrderLimitsQueryVariables>(SampleOrderLimitsDocument, options);
        }
export type SampleOrderLimitsQueryHookResult = ReturnType<typeof useSampleOrderLimitsQuery>;
export type SampleOrderLimitsLazyQueryHookResult = ReturnType<typeof useSampleOrderLimitsLazyQuery>;
export type SampleOrderLimitsQueryResult = Apollo.QueryResult<SampleOrderLimitsQuery, SampleOrderLimitsQueryVariables>;
export const SampleShippingPriceDocument = gql`
    query sampleShippingPrice {
  preRolledPage {
    data {
      id
      attributes {
        sampleShippingPrice
      }
    }
  }
}
    `;
export function useSampleShippingPriceQuery(baseOptions?: Apollo.QueryHookOptions<SampleShippingPriceQuery, SampleShippingPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SampleShippingPriceQuery, SampleShippingPriceQueryVariables>(SampleShippingPriceDocument, options);
      }
export function useSampleShippingPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SampleShippingPriceQuery, SampleShippingPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SampleShippingPriceQuery, SampleShippingPriceQueryVariables>(SampleShippingPriceDocument, options);
        }
export type SampleShippingPriceQueryHookResult = ReturnType<typeof useSampleShippingPriceQuery>;
export type SampleShippingPriceLazyQueryHookResult = ReturnType<typeof useSampleShippingPriceLazyQuery>;
export type SampleShippingPriceQueryResult = Apollo.QueryResult<SampleShippingPriceQuery, SampleShippingPriceQueryVariables>;
export const ShipmentOrderDocument = gql`
    query shipmentOrder($id: ID!) {
  shipmentOrder(id: $id) {
    data {
      ...ShipmentOrder
    }
  }
}
    ${ShipmentOrderFragmentDoc}
${ContactInfoFragmentDoc}
${OrderFragmentDoc}
${TypeFragmentDoc}
${PaperOptionFragmentDoc}
${PaperVarietyFragmentDoc}
${PaperFragmentDoc}
${SizeFragmentDoc}
${FileFragmentDoc}
${PricePolicyFragmentDoc}
${UsersPermissionUserFragmentDoc}
${FrontLogoFragmentDoc}
${BackLogoFragmentDoc}
${FilterFragmentDoc}`;
export function useShipmentOrderQuery(baseOptions: Apollo.QueryHookOptions<ShipmentOrderQuery, ShipmentOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentOrderQuery, ShipmentOrderQueryVariables>(ShipmentOrderDocument, options);
      }
export function useShipmentOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentOrderQuery, ShipmentOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentOrderQuery, ShipmentOrderQueryVariables>(ShipmentOrderDocument, options);
        }
export type ShipmentOrderQueryHookResult = ReturnType<typeof useShipmentOrderQuery>;
export type ShipmentOrderLazyQueryHookResult = ReturnType<typeof useShipmentOrderLazyQuery>;
export type ShipmentOrderQueryResult = Apollo.QueryResult<ShipmentOrderQuery, ShipmentOrderQueryVariables>;
export const ShipmentOrdersDocument = gql`
    query shipmentOrders($filters: ShipmentOrderFiltersInput, $page: Int, $pageSize: Int) {
  shipmentOrders(
    filters: $filters
    sort: ["createdAt:desc"]
    pagination: {page: $page, pageSize: $pageSize}
  ) {
    data {
      id
      attributes {
        shipmentType
        orders {
          data {
            id
            attributes {
              type {
                data {
                  id
                  attributes {
                    name
                    shippingCoefficient
                  }
                }
              }
              size {
                data {
                  attributes {
                    name
                  }
                }
              }
              preRolledSize {
                data {
                  id
                  attributes {
                    size
                    dimension
                  }
                }
              }
              paper_option {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              count
              price
              filter {
                data {
                  id
                  attributes {
                    value
                  }
                }
              }
              cartPreview {
                data {
                  id
                  attributes {
                    previewUrl
                    url
                  }
                }
              }
              conesPerBox
              isPreRolled
              isSample
            }
          }
        }
        client {
          data {
            ...UsersPermissionUser
          }
        }
        ContactInfo {
          email
        }
        payed
        via_invoice
        confirmationToken
        status
        orderPrice
        shipmentPrice
        totalPrice
      }
    }
    meta {
      pagination {
        page
        pageSize
        pageCount
        total
      }
    }
  }
}
    ${UsersPermissionUserFragmentDoc}`;
export function useShipmentOrdersQuery(baseOptions?: Apollo.QueryHookOptions<ShipmentOrdersQuery, ShipmentOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentOrdersQuery, ShipmentOrdersQueryVariables>(ShipmentOrdersDocument, options);
      }
export function useShipmentOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentOrdersQuery, ShipmentOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentOrdersQuery, ShipmentOrdersQueryVariables>(ShipmentOrdersDocument, options);
        }
export type ShipmentOrdersQueryHookResult = ReturnType<typeof useShipmentOrdersQuery>;
export type ShipmentOrdersLazyQueryHookResult = ReturnType<typeof useShipmentOrdersLazyQuery>;
export type ShipmentOrdersQueryResult = Apollo.QueryResult<ShipmentOrdersQuery, ShipmentOrdersQueryVariables>;
export const ShippingPolicyDocument = gql`
    query shippingPolicy {
  shippingPolicy {
    data {
      attributes {
        text
      }
    }
  }
}
    `;
export function useShippingPolicyQuery(baseOptions?: Apollo.QueryHookOptions<ShippingPolicyQuery, ShippingPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShippingPolicyQuery, ShippingPolicyQueryVariables>(ShippingPolicyDocument, options);
      }
export function useShippingPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShippingPolicyQuery, ShippingPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShippingPolicyQuery, ShippingPolicyQueryVariables>(ShippingPolicyDocument, options);
        }
export type ShippingPolicyQueryHookResult = ReturnType<typeof useShippingPolicyQuery>;
export type ShippingPolicyLazyQueryHookResult = ReturnType<typeof useShippingPolicyLazyQuery>;
export type ShippingPolicyQueryResult = Apollo.QueryResult<ShippingPolicyQuery, ShippingPolicyQueryVariables>;
export const SizesDocument = gql`
    query sizes {
  sizes {
    data {
      id
      attributes {
        ...Size
        types {
          data {
            id
            attributes {
              name
            }
          }
        }
        cone_img {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    ${SizeFragmentDoc}`;
export function useSizesQuery(baseOptions?: Apollo.QueryHookOptions<SizesQuery, SizesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SizesQuery, SizesQueryVariables>(SizesDocument, options);
      }
export function useSizesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SizesQuery, SizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SizesQuery, SizesQueryVariables>(SizesDocument, options);
        }
export type SizesQueryHookResult = ReturnType<typeof useSizesQuery>;
export type SizesLazyQueryHookResult = ReturnType<typeof useSizesLazyQuery>;
export type SizesQueryResult = Apollo.QueryResult<SizesQuery, SizesQueryVariables>;
export const TermsOfUseDocument = gql`
    query termsOfUse {
  termsOfUse {
    data {
      attributes {
        text
      }
    }
  }
}
    `;
export function useTermsOfUseQuery(baseOptions?: Apollo.QueryHookOptions<TermsOfUseQuery, TermsOfUseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TermsOfUseQuery, TermsOfUseQueryVariables>(TermsOfUseDocument, options);
      }
export function useTermsOfUseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TermsOfUseQuery, TermsOfUseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TermsOfUseQuery, TermsOfUseQueryVariables>(TermsOfUseDocument, options);
        }
export type TermsOfUseQueryHookResult = ReturnType<typeof useTermsOfUseQuery>;
export type TermsOfUseLazyQueryHookResult = ReturnType<typeof useTermsOfUseLazyQuery>;
export type TermsOfUseQueryResult = Apollo.QueryResult<TermsOfUseQuery, TermsOfUseQueryVariables>;
export const TypesDocument = gql`
    query types {
  types(sort: ["id:asc"]) {
    data {
      id
      attributes {
        name
        isTop
        pricePolicy {
          ...PricePolicy
        }
        image {
          data {
            attributes {
              url
            }
          }
        }
        paper_options {
          data {
            id
            attributes {
              ...PaperOption
            }
          }
        }
        sizes {
          data {
            id
            attributes {
              ...Size
            }
          }
        }
        paper_varieties(sort: ["createdAt:asc"]) {
          data {
            id
            attributes {
              ...PaperVariety
            }
          }
        }
        showRequestForm
        sub_types {
          data {
            id
            attributes {
              name
              image {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${PricePolicyFragmentDoc}
${PaperOptionFragmentDoc}
${SizeFragmentDoc}
${PaperVarietyFragmentDoc}
${PaperFragmentDoc}`;
export function useTypesQuery(baseOptions?: Apollo.QueryHookOptions<TypesQuery, TypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TypesQuery, TypesQueryVariables>(TypesDocument, options);
      }
export function useTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TypesQuery, TypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TypesQuery, TypesQueryVariables>(TypesDocument, options);
        }
export type TypesQueryHookResult = ReturnType<typeof useTypesQuery>;
export type TypesLazyQueryHookResult = ReturnType<typeof useTypesLazyQuery>;
export type TypesQueryResult = Apollo.QueryResult<TypesQuery, TypesQueryVariables>;