export enum ePage {
  checkout = 'checkout',
}

export enum Types {
  paper = 'paper',
  ceramic = 'ceramic',
  glass = 'glass',
  wood = 'wood',
}

export enum PaperOption {
  refinedWitePaper = 'refined white paper',
  organicHempPaper = 'organic hemp paper',
  bluntsHempWrap = 'blunts hemp wrap',
  hempPaper = 'hemp paper',
}

export enum iShippingMethod {
  combined = 'combined',
  air = 'air',
  standard = 'standard',
}

export enum orderStatuses {
  pending = 'pending',
  approved = 'approved',
  shipped = 'shipped',
  delivered = 'delivered',
  declined = 'declined',
}

export enum tokenStatuses {
  valid = 'valid',
  invalid = 'invalid',
  confirmed = 'already confirmed',
  declined = 'already declined',
}

export enum paymentMethod {
  card = 'card',
  invoice = 'invoice',
}
