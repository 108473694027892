import { Layout as BaseLayout } from 'antd';
import { FC, Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Footer, Header, Loader } from '.';
import { Cart } from '../elements/Cart';
import { useApp } from '../app';

const Layout: FC = () => {
  const {
    app: { isOpenCart },
  } = useApp();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <BaseLayout className="baseLayoutStyle">
      <BaseLayout.Header>
        <Header />
      </BaseLayout.Header>
      <BaseLayout>
        <Suspense>
          {isOpenCart && <Cart />}
          <Outlet />
        </Suspense>
      </BaseLayout>
      <BaseLayout.Footer>
        <Footer />
      </BaseLayout.Footer>
    </BaseLayout>
  );
};

export { Layout };
