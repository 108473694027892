import { Button, Form, Input, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { useModalContext } from 'src/context/ModalProvider';

export const PasswordRecoverCode: React.FC = () => {
  const { closeModal, openModal, meta } = useModalContext()
  const [form] = useForm()
  const [loading, setLoading] = useState<boolean>(false)

  const resend = async () => {
    setLoading(true);
    
    try {
      await fetch(`/api/auth/forgot-password-code`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: meta?.email || ''})
      })
    } catch(error) {}

    setLoading(false);
  }

  const onFinish = async (fields: any) => {
    openModal('password-recover-set-new', {
      code: fields.code,
      email: meta?.email || ''
    })
  }

  return (
    <div >
      <Form
        form={form}
        name={'recieve-code-form'}
        layout={'vertical'}
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          name={'code'}
          label="Code"
          rules={[
            {
              required: true,
              message: 'Please input code!',
            },
          ]}
        >
          <Input size="large" placeholder="____" />
        </Form.Item>
        
        <div className="buttons-container">
          <Form.Item noStyle>
            <Button block className="secondary-btn" onClick={closeModal}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item noStyle>
            <Button block type="primary" htmlType="submit" disabled={loading}>
              Send
            </Button>
          </Form.Item>
        </div>
        <div>
          <span style={{ color: '#888698' }}>
            Didn't get the code?
          </span>
          <Button
            style={{ color: '#0ef076' }}
            onClick={resend}
            type={'link'}
            disabled={loading}
          >
            Send again
          </Button>
        </div>
      </Form>
    </div>
  );
}
