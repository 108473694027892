import './OrderItem.less';
import { Input, Row, Typography } from 'antd';
import { FC, useState } from 'react';
import { ReactComponent as DeleteSVG } from '../../../assets/icons/delete.svg';
import { ReactComponent as ArrowDownSVG } from '../../../assets/icons/chevron-down.svg';
import CustomButton from '../../ui/CustomButton/CustomButton';
import { formatPrice } from '../../../helpers/formatPrice';
import { ConeDetails } from '../cone-details';
import { iCollapseDetailsItem } from '../../../pages/configurator/components/ResultStep';
import { useLocation, useNavigate } from 'react-router';
import { useApp } from '../../app';
import { useConfiguratorQuery } from 'src/graphql';

interface iProps {
  name: string;
  img: string;
  id: string | null | undefined;
  typeId: string | null | undefined;
  subTypeId?: string | null | undefined;
  onDelete?: () => void;
  totalPrice: number;
  count: number;
  onChangeCount?: (value: number) => void;
  collapseProps?: iCollapseDetailsItem;
  editable?: boolean;
  cones: number;
  frontLogo?: Record<string, any> | null;
  backLogo?: Record<string, any> | null;
  isPreRolled: boolean | null | undefined;
  isSample?: boolean | null | undefined;
  paperOptionName?: string | null | undefined;
  filterColor?: string | null | undefined;
  minBoxesOrder?: number;
  preRolledSize?: string;
}

const OrderItem: FC<iProps> = ({
  name,
  collapseProps,
  img,
  onDelete,
  totalPrice,
  count,
  onChangeCount,
  id,
  typeId,
  subTypeId,
  cones,
  frontLogo = {},
  backLogo = {},
  editable = true,
  isPreRolled,
  isSample,
  paperOptionName,
  filterColor,
  minBoxesOrder = 1,
  preRolledSize,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    app: { setCartItem, setIsOpenCart },
  } = useApp();
  const location = useLocation().pathname;
  const navigator = useNavigate();
  const { data: configuratorData } = useConfiguratorQuery();

  const backgroundImageUrl =
    configuratorData?.configurator?.data?.attributes?.filterColorBackground?.data?.attributes?.url;

  const onEdit = ({ id, step }: { id: string; step: number }) => {
    const editingItem = {
      id,
      step,
      frontLogo: {
        angle: frontLogo?.angle,
        id: frontLogo?.id,
        url: frontLogo?.logo.data?.attributes?.url,
        logoWidth: frontLogo?.logoWidth,
        imageId: frontLogo?.logo.data?.id,
        isUploaded: false,
      },
      backLogo: {
        angle: backLogo?.angle,
        color: backLogo?.color,
        id: backLogo?.id,
        url: backLogo?.logo.data?.attributes?.url,
        imageId: backLogo?.logo.data?.id,
        isUploaded: false,
        text: backLogo?.text,
        type: null,
        logoWidth: backLogo?.logoWidth,
      },
      type: typeId,
      subType: subTypeId,
      paperOptionName,
      filterColor,
    };

    setCartItem(editingItem);
    setIsOpenCart(false);

    if (location !== '/configurator') {
      navigator('/configurator');
    }
  };

  return (
    <div className={'order-item'}>
      <div className={'order-item-info'}>
        <Row className={'item-top'} wrap={false}>
          <div
            className={isPreRolled ? 'cart-img-wrapper-preRolled' : 'cart-img-wrapper-configurator'}
            style={{
              backgroundImage: !isPreRolled ? `url(${backgroundImageUrl || ''})` : undefined,
            }}
          >
            <img
              src={img}
              alt={name}
              className={isPreRolled ? 'cart-img-preRolled' : 'cart-img-configurator'}
            />
          </div>
          <div className={'item-title'}>
            {isSample && (
              <Typography.Text style={{ color: '#fff', fontSize: 16 }}>Sample</Typography.Text>
            )}
            <Typography.Text style={{ color: '#fff', fontSize: 16 }}>{name}</Typography.Text>
            <Typography.Text style={{ fontSize: 14, color: '#fff' }}>
              {cones} per box
            </Typography.Text>
            {preRolledSize && isPreRolled && (
              <Typography.Text style={{ color: '#fff' }}>Size: {preRolledSize}</Typography.Text>
            )}
          </div>
          {!!onDelete && (
            <CustomButton
              label={<DeleteSVG />}
              type={'link'}
              onClick={onDelete}
              className={`delete-btn`}
            />
          )}
        </Row>

        <Row justify={'space-between'} align={'middle'}>
          {!!onChangeCount && !isSample ? (
            <Row className={'count-box'} wrap={false}>
              <CustomButton
                label={'-'}
                type={'link'}
                className={'input-btn'}
                onClick={() => count > minBoxesOrder && onChangeCount(count - 1)}
              />
              <Input value={count} disabled className={'count-input'} />
              <CustomButton
                label={'+'}
                type={'link'}
                className={'input-btn'}
                onClick={() => onChangeCount(count + 1)}
              />
            </Row>
          ) : (
            <Typography.Text style={{ color: '#fff' }}>{count} box</Typography.Text>
          )}
          <Typography.Title level={5} style={{ color: '#fff', margin: 0 }}>{`$ ${formatPrice(
            totalPrice
          )}`}</Typography.Title>
        </Row>
      </div>
      {!!collapseProps && (
        <div className={'order-item-collapse'}>
          <div className={'collapse-item'} onClick={() => setIsOpen((prevState) => !prevState)}>
            <ArrowDownSVG style={{ rotate: isOpen ? '180deg' : '0deg' }} />
          </div>
          {isOpen && (
            <div className={'order-item-details'}>
              <ConeDetails
                data={collapseProps}
                isMobile={true}
                setCurrentStep={(step) => onEdit({ id: id!, step })}
                editable={editable}
                subTypeId={subTypeId}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderItem;
