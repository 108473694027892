import { Button } from 'antd';
import React from 'react';
import { SvgIcon } from 'src/components/icons/SvgIcon';
import { FormType } from '../..';

type SuccessRegistrationProps = {
  setFormType: React.Dispatch<React.SetStateAction<FormType>>;
};

export const SuccessRegistration: React.FC<SuccessRegistrationProps> = ({ setFormType }) => {
  return (
    <div className={'success-registration'}>
      <SvgIcon type={'success'} />
      <p style={{ color: '#fff' }}>
        Account Created! We're delighted to welcome you to our community.
      </p>
      <Button
        onClick={() => {
          setFormType('login');
        }}
      >
        Log in
      </Button>
    </div>
  );
};
