export interface IPricePolicy {
  id: string;
  conesFrom: number;
  conesTo: number;
  pricePerCone: number;
}

export const findPricePolicy = (
  conesPerBox: number,
  pricePolicy: (IPricePolicy | null | undefined)[],
  quontity: number
) => {
  const findObj = pricePolicy?.find((item: any) => {
    return item?.conesFrom < conesPerBox * quontity && item?.conesTo >= conesPerBox * quontity;
  });

  if (findObj) {
    return findObj;
  }

  const maxConeObject = pricePolicy.reduce((maxObj, policy) => {
    if (!maxObj || !policy) return;

    return policy.conesTo > maxObj.conesTo ? policy : maxObj;
  }, pricePolicy[0]);

  if (!findObj && maxConeObject && conesPerBox * quontity > maxConeObject.conesTo) {
    return maxConeObject;
  }

  return null;
};

export const getPriceAccodingToPricePolicy = (
  orderForChange: Record<string, any>,
  pricePolicyPreRolledCones: Record<string, any>,
  pricePolicyConfiguratorCones: Record<string, any>,
  count: number
) => {
  const isRolled = orderForChange?.attributes?.isPreRolled;
  const conesPerBox = orderForChange?.attributes?.conesPerBox;

  const pricePolicy = isRolled ? pricePolicyPreRolledCones : pricePolicyConfiguratorCones;

  const obj = findPricePolicy(conesPerBox, pricePolicy.pricePolicy, count);

  if (!obj?.pricePerCone) return;

  const newPricePerBox = obj?.pricePerCone * conesPerBox;

  return newPricePerBox;
};
