import { Button, Form, Input, Row, Col, Divider, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { useModalContext } from 'src/context/ModalProvider';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadContainer } from 'src/components/elements/upload-container';
import { dataURLtoFile } from 'src/helpers/dataUrlToFile';

export const ConfiguratorRequestForm: React.FC = () => {
  const { closeModal, openModal, meta } = useModalContext()
  const [form] = useForm();
  
  const [loading, setLoading] = useState<boolean>(false);

  const [file, setFile] = useState<UploadFile | undefined>();

  const onFinish = async (fields: any) => {
    if ( file ) {
      if ( file.thumbUrl && file.originFileObj) {
        fields.logo = dataURLtoFile({dataurl: file.thumbUrl, filename: `logo-${new Date().toISOString()}-${file.originFileObj.name}`})
      }
    }

    if (meta.type) {
      fields.type = meta.type
    }

    const formData = new FormData();

    if (fields.logo) {
      formData.append(`files.logo`, fields.logo, fields.logo.filename);
    }
    formData.append('data', JSON.stringify({...fields, companyName: fields['company-name']}))

    setLoading(true)
    try {
      await fetch('/api/configurator-form-requests', {
        method: 'POST',
        body: formData,
      });
    } catch(error) {}
    setLoading(false)

    openModal('request-successful', {
      message: 'Your request has been received. Please wait, our manager will contact you within 15 minutes'
    })
  }

  return (
    <div className="configurator-request-form">
      <Divider type={'horizontal'} />
      <Typography.Text style={{color:'#888698'}}>
        Unfortunately, this function does not work at the moment, please send your details, our manager will contact you shortly
      </Typography.Text>

      <Form
        form={form}
        name={'configurator-request-form'}
        layout={'vertical'}
        onFinish={onFinish}
        requiredMark={false}
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name={'name'}
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'Please input your name!',
                },
              ]}
            >
              <Input type="text" placeholder="Enter name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name={'phone'}
              label="Cell phone number"
              rules={[
                {
                  required: true,
                  message: 'Please input your phone!',
                },
              ]}
            >
              <Input type="text" placeholder="Enter phone number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={24} md={12}>
            <Form.Item
              name={'company-name'}
              label="Company name"
            >
              <Input type="text" placeholder="Enter name" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name={'email'}
              label="Email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input type="email" placeholder="Enter your email" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name={'logo'}>
          <UploadContainer file={file} setFile={setFile} />
        </Form.Item>

        <div className="buttons-container">
          <Form.Item noStyle>
            <Button block className="secondary-btn" onClick={closeModal}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item noStyle>
            <Button block type="primary" htmlType="submit" disabled={loading}>
              Send
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
