import { Spin, SpinProps } from "antd"
import { FC } from "react"

const Loader: FC<SpinProps> = () => (
  <div
    style={{
      position: 'fixed',
      top: '40%',
      left: '50%',
      zIndex: '1001',
      transform: 'translateX(-50%)',
    }}
  >
    <Spin size={'large'} />
  </div>
);

export { Loader }
