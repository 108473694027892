import { ThemeConfig } from 'antd/es/config-provider';

const theme: ThemeConfig = {
  inherit: false,
  token: {
    colorPrimary: '#0EF076',
    colorBgMask: 'rgba(21, 20, 28, 0.8)',
  },
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: 'unset',
      colorActiveBarHeight: 0,
      colorPrimaryBg: 'unset',
    },
    Layout: {
      colorBgLayout: 'unset',
      bodyBg: '#FFF',
      headerPadding: 0,
      headerHeight: 'auto' as any,
      footerPadding: 0,
    },
    Button: {
      colorPrimary: '#0EF076',
      colorPrimaryHover: '#6EF6AD',
      colorPrimaryActive: '#0BC05E',
      colorPrimaryText: '#15141C',
      colorText: '#0BC05E',
      borderRadius: 12,
      borderRadiusSM: 5,
      borderRadiusLG: 12,
      fontSizeXL: 12,
      fontSizeLG: 12,
      fontSizeSM: 10,
      controlHeightLG: 56,
      controlHeight: 40,
    },
    Typography: {
      fontSizeHeading1: 48,
      fontSizeHeading2: 30,
      fontSizeHeading3: 24,
      fontSizeHeading4: 20,
      fontSizeHeading5: 14,
      fontSize: 14,
    },
    Radio: {
      colorPrimary: '#33323A',
      colorBgContainer: 'rgba(231, 231, 234, 1)',
      colorBorder: 'rgba(207, 207, 214, 1)',
      controlHeight: 40,
      borderRadius: 20,
    },
    Upload: {
      colorPrimaryHover: 'rgba(236, 0, 140, 1)',
      colorFillAlter: '#FAFBFB',
    },
    Segmented: {
      colorBgLayout: '#F5F5F8',
      itemSelectedBg: '#15141C',
      colorText: 'white',
      controlHeight: 56,
      lineWidthBold: 8,
      borderRadiusSM: 28,
      borderRadius: 28,
    },
    Slider: {
      handleActiveColor: '#EC008C',
      handleColor: '#EC008C',
      trackBg: '#EC008C',
      trackHoverBg: '#EC008C',
      railBg: '#FAE7F3',
      railHoverBg: '#FAE7F3',
    },
  },
};

export default theme;
