import { FC, useState } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useAuth } from 'src/context/AuthProvider';
import { useLayoutQuery } from 'src/graphql';
import { Logo } from '../../logo';
import { HeaderMenu } from '../../menu/HeaderMenu';
import { MobileMenu } from './Drawer/MobileMenu';

import { Typography } from 'antd';
import { Auth } from 'src/new-components/Auth';
import Container from '../../ui/Container/Container';
import './Header.less';

const Header: FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const isMaxTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const { user } = useAuth();

  const {
    menu_nav_nonAuthorized,
    menu_nav_authorized,
    header_nav_authorized,
    header_nav_nonAuthorized,
    phone_number,
  } = useLayoutQuery().data?.layout?.data?.attributes || {};
  const onMenuToggle = () => {
    setMenuOpen((prevState) => !prevState);
  };
  const menuData = user ? menu_nav_authorized : menu_nav_nonAuthorized;
  const headerNavData = user ? header_nav_authorized : header_nav_nonAuthorized;

  return (
    <Container dark id={'header'} className={'custom-bg'} md>
      {isMaxTablet && phone_number && (
        <div className={'left-wrapper'}>
          <Typography.Link className={'phone'} href={`tel:${phone_number}`}>
            {phone_number}
          </Typography.Link>
        </div>
      )}
      {isMaxTablet && <Logo />}

      <HeaderMenu
        headerNavData={headerNavData as ComponentUiLink[]}
        menuData={menuData as ComponentUiLink[]}
        phone_number={phone_number!}
        onMenuOpen={onMenuToggle}
      />

      {isMaxTablet && (
        <MobileMenu
          onMenuToggle={onMenuToggle}
          menuItems={menuData as ComponentUiLink[]}
          isOpen={menuOpen}
        />
      )}

      <Auth />
    </Container>
  );
};

export { Header };
