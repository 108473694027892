import { Button, Form, Input, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { useModalContext } from 'src/context/ModalProvider';
import { getAuthToken } from 'src/helpers/auth';
import { useAuth } from 'src/context/AuthProvider';

export const DeleteAccount: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = useForm();
  const { closeModal, openModal } = useModalContext();
  const { logout } = useAuth();

  const onFinish = async (fields: any) => {
    setLoading(true);

    await fetch('/api/auth/delete-account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
      body: JSON.stringify(fields),
    }).catch(console.warn);

    setLoading(false);

    logout();

    openModal('request-successful', {
      message: 'Thank you for being with us. We appreciate your time with us.',
    });
  };

  return (
    <div className="delete-account-form">
      <Typography.Paragraph style={{ color: '#fff' }}>
        Please note that this action is irreversible. All your data will be deleted. If you are sure
        of your decision, enter the word "Delete" to confirm the deletion of the account.
      </Typography.Paragraph>

      <Form
        form={form}
        name={'delete-account-form  x'}
        layout={'vertical'}
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          name={'delete'}
          label="Delete account"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || value.toLowerCase() !== 'delete') {
                  return Promise.reject(new Error('Enter "delete" to confirm'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input size="large" placeholder="Enter necessary word" />
        </Form.Item>
        <Form.Item name={'reason'} label="Message">
          <Input.TextArea size="large" placeholder="Write why you decided to delete your account" />
        </Form.Item>

        <div className="buttons-container">
          <Form.Item noStyle>
            <Button block className="secondary-btn" onClick={closeModal}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item noStyle>
            <Button block type="primary" htmlType="submit" disabled={loading}>
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
