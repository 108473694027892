import { FC, ReactNode } from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import { OrderImg } from '../OrderImg';
import { ReactComponent as IsTopSVG } from '../../../assets/icons/is-top.svg';
import './OrderNameWithImage.less';
import { ePage } from '../../../enums/page';

export const OrderNameWithImg: FC<{
  name?: string | ReactNode;
  icon?: { src?: string; type?: 'circle' | 'square' };
  titleClassName?: string;
  info?: string | ReactNode;
  isTop?: boolean;
  details?: any | null | undefined;
  page?: ePage;
}> = ({ icon, name, isTop, titleClassName, info, details, page }) => {
  return (
    <Row gutter={[16, 0]} wrap={false} align={'middle'}>
      {icon?.src && (
        <Col>
          <OrderImg src={icon.src} type={icon?.type} />
        </Col>
      )}
      {isTop && <IsTopSVG className={'order-name-top'}>TOP</IsTopSVG>}
      <Col>
        <Row>
          <Typography.Text className={titleClassName} style={{ color: '#fff' }}>
            {name}
          </Typography.Text>
        </Row>
        {info && (
          <Row>
            <Typography.Text className={'info-text'} style={{ opacity: 0.7, fontSize: 10 }}>
              {info}
            </Typography.Text>
          </Row>
        )}
        {details && (
          <>
            <Row>
              {page === ePage.checkout && <Divider style={{ width: '70px', background: '#fff' }} />}
              <Typography.Text style={{ fontSize: 12, fontWeight: 400, color: '#fff' }}>
                {details}
              </Typography.Text>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};
