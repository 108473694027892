import { FC } from 'react';

import { Link } from 'react-router-dom';
import './index.less';
import { useLogoQuery } from 'src/graphql';
import { useMediaQuery } from 'react-responsive';

const Logo: FC<{ logo?: string }> = ({ logo }) => {
  const url = useLogoQuery().data?.layout?.data?.attributes?.logo?.data?.attributes?.url;
  const isMaxTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <Link to={'/'} id={'logo'}>
      {url && (
        <img
          className="logo-img"
          src={logo ? logo : url}
          alt="logo"
          width={!isMaxTablet ? 138 : 73}
          height={!isMaxTablet ? 106 : 59}
        />
      )}
    </Link>
  );
};

export { Logo };
