import { FC, lazy, useEffect, useState } from 'react';
import { Outlet, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';

import DefaultLayout, { Loader } from '../components/layout';
import { useAuth } from 'src/context/AuthProvider';

const Home = lazy<FC>(() => import('./home'));
const About = lazy<FC>(() => import('./about'));
const Checkout = lazy<FC>(() => import('./checkout'));
const Configurator = lazy<FC>(() => import('./configurator'));
const Contact = lazy<FC>(() => import('./contact'));
const Shop = lazy<FC>(() => import('./shop'));
const PrivacyPolicy = lazy<FC>(() => import('./privacy-policy'));
const ShippingPolicy = lazy<FC>(() => import('./shipping-policy'));
const TermsOfService = lazy<FC>(() => import('./terms-of-use'));
const PreRolled = lazy<FC>(() => import('./pre-rolled'));
const PreRolledSample = lazy<FC>(() => import('./pre-rolled-sample'));
const MyOrders = lazy<FC>(() => import('./my-orders'));
const MyCones = lazy<FC>(() => import('./my-cones'));
const Account = lazy<FC>(() => import('./account'));
const FAQ = lazy<FC>(() => import('./faq'));
const PaymentProcess = lazy<FC>(() => import('./payment-process'));
const ConfirmOrder = lazy<FC>(() => import('./confirm-order'));
const NotFound = lazy<FC>(() => import('./not-found/NotFound'));

const defaultRoutes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader />,
    children: [
      {
        Component: Home,
        path: '',
        index: true,
      },
      {
        Component: About,
        path: '/about-us',
      },
      {
        Component: PaymentProcess,
        path: '/payment',
      },
      {
        Component: Checkout,
        path: '/checkout',
      },
      {
        Component: Configurator,
        path: '/configurator',
      },
      {
        Component: Shop,
        path: '/shop',
      },
      {
        Component: FAQ,
        path: '/faq',
      },
      {
        Component: PrivacyPolicy,
        path: '/privacy-policy',
      },
      {
        Component: TermsOfService,
        path: '/terms-of-service',
      },
      {
        Component: ShippingPolicy,
        path: '/shipping-policy',
      },
      {
        Component: PreRolled,
        path: '/pre-rolled',
      },
      {
        Component: PreRolledSample,
        path: '/pre-rolled-sample',
      },
      {
        Component: Contact,
        path: '/contact',
      },
      {
        Component: NotFound,
        path: '*',
      },
    ],
  },
  { Component: ConfirmOrder, path: '/confirm-order' },
];

const authRoutes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader />,
    children: [
      {
        Component: MyOrders,
        path: '/orders',
      },
      {
        Component: MyCones,
        path: '/my-cones',
      },
      {
        Component: Account,
        path: '/account',
      },
      {
        Component: NotFound,
        path: '*',
      },
    ],
  },
];

const Router: FC = () => {
  const { user } = useAuth();
  const [routes, setRoutes] = useState<RouteObject[]>(defaultRoutes);

  useEffect(() => {
    if (user?.id) {
      setRoutes([...defaultRoutes, ...authRoutes]);
    } else {
      setRoutes([...defaultRoutes]);
    }
  }, [user]);

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} fallbackElement={<Loader />} />;
};

export default Router;
