import { Drawer, Modal } from 'antd';
import { useMediaQuery } from 'react-responsive';
import './index.less';
import { Fragment } from 'react';
import { FormType } from '../..';

type AuthContainerProps = {
  title: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFormType: React.Dispatch<React.SetStateAction<FormType>>;
};

export const AuthContainer: React.FC<React.PropsWithChildren<AuthContainerProps>> = ({
  title,
  open,
  setOpen,
  setFormType,
  children,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 440px)' });

  return (
    <Fragment>
      {isMobile ? (
        <Drawer
          rootClassName={'auth-container-drawer'}
          title={title}
          open={open}
          onClose={() => {
            setOpen(false);
            setFormType('login');
          }}
          placement={'bottom'}
          height={'max-content'}
        >
          {children}
        </Drawer>
      ) : (
        <Modal
          centered
          rootClassName={'auth-container-modal'}
          title={title}
          open={open}
          footer={null}
          onCancel={() => {
            setOpen(false);
            setFormType('login');
          }}
          style={{ maxWidth: '450px' }}
        >
          {children}
        </Modal>
      )}
    </Fragment>
  );
};
