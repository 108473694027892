import './index.less'
import { Drawer, Modal } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useModalContext } from 'src/context/ModalProvider';

import { ConfiguratorRequestForm } from './ui/ConfiguratorRequest';
import { RequestSuccessful } from './ui/RequestSuccessful';
import { ChangePasswordForm } from './ui/ChangePassword';
import { DeleteAccount } from './ui/DeleteAccount';
import { PasswordRecoverCode } from './ui/PasswordRecoverCode';
import { PasswordRecoverSetNew } from './ui/PasswordRecoverSetNew';

export type FormType = 'configurator-unavailable-request' | 'request-successful' | 'change-password' 
  | 'delete-account' | 'password-recover-code' | 'password-recover-set-new';

const titles: Record<FormType, string> = {
  'configurator-unavailable-request': 'Fill the form',
  'request-successful': '',
  'change-password': 'Change password',
  'delete-account': 'Delete account',
  'password-recover-code': 'Enter code',
  'password-recover-set-new': 'Enter new password',
};

const T: React.FC = () => <>awawa</>
const forms: Record<FormType, React.ReactNode> = {
  'configurator-unavailable-request': <ConfiguratorRequestForm />,
  'request-successful': <RequestSuccessful />,
  'change-password': <ChangePasswordForm />,
  'delete-account': <DeleteAccount />,
  'password-recover-code': <PasswordRecoverCode />,
  'password-recover-set-new': <PasswordRecoverSetNew />
};

export const Modals: React.FC = () => {
  const { open, closeModal, formType } = useModalContext();
  const isMobile = useMediaQuery({ query: '(max-width: 440px)' });

  return (
    <Modal
      centered
      destroyOnClose={true}
      rootClassName={'auth-container-modal modals-container-modal'}
      title={formType ? titles[formType] : ''}
      open={open}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
      style={{ maxWidth: 'unset', width: 'unset' }}
      width={"unset"}
    >
      { formType ? forms[formType] : null }
    </Modal>
  )
}
