import { FC, ReactNode } from 'react';
import './Container.less';
import { classNames } from '../../../helpers/classNames';

type Props = {
  className?: string;
  children: ReactNode;
  pb?: boolean;
  pt?: boolean;
  pbs?: boolean;
  pts?: boolean;
  dark?: boolean;
  id?: string;
  full?: boolean;
  md?: boolean;
  pbmd?: boolean;
};

const Container: FC<Props> = ({
  className,
  id,
  children,
  pt,
  dark,
  pb,
  pts,
  pbs,
  pbmd,
  full,
  md,
}) => (
  <div className={classNames('container', [className], { pb, pt, pbs, pts, pbmd, dark, full })}>
    <div id={id} className={classNames('container-wrapper', [], { full, md })}>
      {children}
    </div>
  </div>
);

export default Container;
