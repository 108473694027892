import { FC, SVGProps } from 'react';
import { ReactComponent as Cart } from '../../assets/icons/Cart.svg';
import { ReactComponent as CartDesktop } from '../../assets/icons/CartDesktop.svg';
import { ReactComponent as Facebook } from '../../assets/icons/Facebook.svg';
import { ReactComponent as Menu } from '../../assets/icons/Menu.svg';
import { ReactComponent as Order } from '../../assets/icons/Order.svg';
import { ReactComponent as Fav } from '../../assets/icons/Save.svg';
import { ReactComponent as User } from '../../assets/icons/User.svg';
import { ReactComponent as UserDesktop } from '../../assets/icons/UserDesktop.svg';
import { ReactComponent as Apple } from '../../assets/icons/apple.svg';
import { ReactComponent as Card } from '../../assets/icons/card.svg';
import { ReactComponent as Cone } from '../../assets/icons/cones.svg';
import { ReactComponent as Delivery } from '../../assets/icons/delivery.svg';
import { ReactComponent as Google } from '../../assets/icons/google.svg';
import { ReactComponent as Success } from '../../assets/icons/success.svg';
import { ReactComponent as Upload } from '../../assets/icons/upload-illustration.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as Info } from '../../assets/icons/info-icon.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as Minus } from '../../assets/icons/minus.svg';
import { ReactComponent as ConeFilter } from '../../assets/icons/cone-filter.svg';

const icons = {
  info: Info,
  profile: User,
  profileDesktop: UserDesktop,
  success: Success,
  cart: Cart,
  cartDesktop: CartDesktop,
  orders: Order,
  favorites: Fav,
  favoritesDesktop: Fav,
  'cones-shop': Cone,
  menu: Menu,
  upload: Upload,
  google: Google,
  facebook: Facebook,
  apple: Apple,
  delivery: Delivery,
  card: Card,
  plus: Plus,
  minus: Minus,
  'arrow-right': ArrowRight,
  'arrow-left': ArrowLeft,
  coneFilter: ConeFilter,
} as const;

type SvgIconProps = SVGProps<SVGSVGElement> & { type: keyof typeof icons };

export const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type] ?? null;
  return Icon && <Icon {...svgProps} />;
};
