export const formatPrice = (price: number): string => {
  const [wholePart, decimalPart] = price.toFixed(2).toString().split('.');

  const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const formattedPrice = `${formattedWholePart}.${decimalPart}`;

  return formattedPrice;
};

export const formatNumber = (price: number): string => {
  const [wholePart] = price.toFixed(2).toString().split('.');

  const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, '');

  const formattedPrice = `${formattedWholePart}`;

  return formattedPrice;
};
